import { Col, ListGroup, Row, Button } from 'react-bootstrap';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getModuleListFilterByProvider, getModuleListQuery } from '../../redux/selectors';
import { setModuleListFilterByProvider, setModuleListFilterQuery } from '../../redux/actions';
import { FaCircleCheck } from 'react-icons/fa6';
import { isModifiedEvent } from '../Provider/utils';
import { defaultTitle, moduleDescription } from '../constants';
import { BadgesFilter } from '../Badges/badgesFilter';
import { explodeList } from '../common';
import { FavoriteCount } from '../Favorite/favoriteCount';
import { FavoriteContext } from '../Favorite/favoriteContext';
import { FAVORITE_MODULES_LIST_ID, FAVORITE_PROVIDERS_LIST_ID } from '../Favorite/useFavorite';

const providers = [
    ['alicloud', 'Alibaba', 'alicloud,alibabacloudstack', '/images/icons/providers/alibaba.svg'],
    ['aws', 'AWS', 'aws,awscc', '/images/icons/providers/amazon_aws.svg'],
    ['azurerm', 'Azure', 'azurerm,azuread,azuredevops,azurestack,azapi', '/images/icons/providers/azure.svg'],
    ['cloudflare', 'Cloudflare', 'cloudflare', '/images/icons/providers/cloudflare.svg'],
    ['digitalocean', 'DigitalOcean', 'digitalocean', '/images/icons/providers/digitalocean.svg'],
    ['github', 'GitHub', 'github', '/images/icons/providers/github.svg'],
    ['google', 'Google', 'google,gcp', 'https://avatars3.githubusercontent.com/google'],
    ['helm', 'Helm', 'helm', 'https://avatars3.githubusercontent.com/helm'],
    ['ibm', 'IBM', 'ibm', 'https://avatars3.githubusercontent.com/ibm'],
    ['kubernetes', 'Kubernetes', 'kubernetes', 'https://avatars3.githubusercontent.com/kubernetes'],
    ['linode', 'Linode', 'linode,akamai', 'https://avatars3.githubusercontent.com/linode'],
    ['openstack', 'OpenStack', 'openstack', 'https://avatars3.githubusercontent.com/openstack'],
    ['oracle', 'Oracle', 'oci,oraclepaas,opc', 'https://avatars3.githubusercontent.com/oracle'],
    ['ovh', 'OVH', 'ovh', 'https://avatars3.githubusercontent.com/ovh'],
    ['tencentcloud', 'Tencent', 'tencentcloud', 'https://avatars3.githubusercontent.com/tencentcloud'],
    ['shoreline', 'Shoreline', 'shoreline', 'https://avatars3.githubusercontent.com/shorelinesoftware']
];

export const ModuleListFilter = () => {
    // TODO we do not need selectors here.
    // FIXME provider filter does not clear when passing from the global search (show all).
    const provider = useSelector(getModuleListFilterByProvider);
    const query = useSelector(getModuleListQuery);
    const dispatch = useDispatch();
    const resetFilterRef = useRef(null);
    const [filterParams, setFilterParams] = useSearchParams();

    const { getFavoriteItems } = useContext(FavoriteContext);
    const favoriteItems = getFavoriteItems(FAVORITE_MODULES_LIST_ID);
    const showFavoriteOnly = filterParams.get('favorite') === '1';

    const onFavoriteClickHandler = () => {
        setFilterParams((params) => {
            if (showFavoriteOnly) {
                params.delete('favorite');
            } else {
                params.set('favorite', '1');
            }

            return params;
        });
    }

    const selectedBadgeTypes = explodeList(filterParams.get('badges'));

    const onChangeBadgeFilterHandler = (badgeTypes: string[]) => {
        setFilterParams((params) => {
            if (badgeTypes.length === 0) {
                params.delete('badges');
            } else {
                params.set('badges', badgeTypes.join(','));
            }
            return params;
        });
    }

    const resetFilter = (e: React.MouseEvent) => {
        e.preventDefault();
        setFilter(null);
    };

    const setFilter = (value: null | string) => {
        dispatch(setModuleListFilterByProvider({ provider: value }, setFilterParams));
    };

    useEffect(() => {
        if (filterParams.get('query')) {
            dispatch(setModuleListFilterQuery({ query: filterParams.get('query') }));
        }
        if (filterParams.get('provider')) {
            dispatch(setModuleListFilterByProvider({ provider: filterParams.get('provider') }));
        }
    }, []);

    const providerName = useMemo(() => {
        const result = providers.find((element) => element[2] === provider);

        return result ? result[1] : '';
    }, [provider]);

    return (
        <Row>
            <Row className="mb-2">
                <FavoriteCount
                    count={favoriteItems.length}
                    showFavoriteOnly={showFavoriteOnly}
                    onClick={onFavoriteClickHandler}
                />
            </Row>
            <Row className="mb-2">
                <BadgesFilter
                    subject="module"
                    selectedBadgeTypes={selectedBadgeTypes}
                    onChange={onChangeBadgeFilterHandler}
                />
            </Row>
            <Row>
                <div className="filter-head">
                    <div className="text-secondary">
                        By provider
                    </div>
                    <div>
                        {provider && (
                            <Button
                                ref={resetFilterRef}
                                variant="link"
                                onClick={resetFilter}
                                className="btn border-0 text-decoration-none"
                            >
                                Clear
                            </Button>
                        )}
                    </div>
                </div>
            </Row>
            <Helmet>
                <title>
                    {`${providerName ? providerName + ' modules' : 'Modules'} | ${defaultTitle}`}
                </title>
                <meta
                    name="description"
                    content={`${providerName ? providerName + ' | ' : ''}${moduleDescription}`}
                />
            </Helmet>
            <Row>
                <Col className="pb-4">
                    <ListGroup className="pointer filter-body">
                        {providers.map(function (el: string[]) {
                            return (
                                <ListGroup.Item
                                    key={`filter-${el[0]}`}
                                    action
                                    href={`/modules?provider=${el[2]}`}
                                    onClick={(e) => {
                                        if (!isModifiedEvent(e)) {
                                            setFilter(el[2]);
                                            e.preventDefault();
                                        }
                                    }}
                                    active={provider === el[2]}
                                    className="py-1 d-flex justify-content-between"
                                >
                                    <div>
                                        <span className="provider-logo">
                                            <img src={el[3]} alt={el[1]} className="github-image" />
                                        </span>
                                        {el[1]}
                                    </div>
                                    {provider === el[2] && (
                                        <span className="check text-end">
                                            <FaCircleCheck />
                                        </span>
                                    )}
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </Col>
            </Row>
        </Row>
    );
};
