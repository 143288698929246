import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";

import { Markdown } from '../Markdown';
import { ProviderDocumentationResourceType } from './';
import { ProviderItemType } from '../resourceTypes';
import { extractMetadataFromMarkdown, getCategoryName, getMdDescription, getSubcategoryName } from './utils';
import {defaultTitle} from '../constants';

export type DocContentProps = {
    readonly doc: ProviderDocumentationResourceType;
    readonly sourceBaseURL: string;
    readonly provider: ProviderItemType;
    readonly providerDocumentation: string;
    readonly onComplete?: () => void;
};

export const DocContent: React.FC<DocContentProps> = ({
    doc,
    onComplete,
    sourceBaseURL,
    provider,
    providerDocumentation,
}) => {
    const [content, setContent] = useState<string>();
    const ref = useRef(null);
    const location = useLocation();
    const descriptionRef = useRef('');

    const baseURL = location.pathname?.split('/').slice(0, 5).join('/');
    const categoryURL = location.pathname?.split('/').slice(0, 7).join('/');

    const prefixRegexp = new RegExp('^' + provider.attributes.name.replace(/[\W_]/g, '').replace(/(.)/g, '$1_?'));
    const adjustResourceName = (n: string) => {
        return prefixRegexp.exec(n) ? n : provider.attributes.name + '_' + n;
    };

    useEffect(() => {
        const str = providerDocumentation.replace(/(?:^[\s\w._]*|\r?\n)---\r?\n[\s\S]+?\r?\n-{3,}/, '').trim();
        const metaData = extractMetadataFromMarkdown(providerDocumentation);

        descriptionRef.current = metaData.description || getMdDescription(str) || metaData.title || '';
        setContent(str);

        // SEO title
        if (doc.attributes.title !== 'overview') {
            document.title = `${doc.attributes.category !== 'guides' ? adjustResourceName(doc.attributes.title) : doc.attributes.title}${
                getSubcategoryName(doc) !== '' ? ' | ' + getSubcategoryName(doc) : ''
            } | ${getCategoryName(doc)} | ${provider.attributes.fullName} | Providers | ${defaultTitle}`;
        }

        if (!location.hash) {
            return;
        }

        if (onComplete) {
            setTimeout(() => {
                onComplete();
            }, 100);
        }
    }, [providerDocumentation]);

    const linkOverride = (p: any) => {
        const { node, children, href, ...rest } = p;
        let newHref = p.href;
        const interLinks = [
            [/^(guides|data-sources|resources)\/([\w-]+)(?:\.html)(#[\w-]+)?$/, baseURL + '/docs/$1/$2$3'],
            [/^([\w-]+)(?:\.html)(#[\w-]+)?$/, baseURL + '/docs/guides/$1$2'],
            [/^[.]{2}\/(?:index\.(?:html|md))(#[\w-]+)?$/, baseURL + '$1'],
            [/^(?:\/docs\/providers\/[\w-]+|[.]{2})\/guides\/([\w-]+)\.html(?:\.markdown)?(#[\w-]+)?$/, baseURL + '/docs/guides/$1$2'],
            [/^(?:\/docs\/providers\/[\w-]+|[.]{2})\/r\/([\w-]+)\.html(?:\.markdown)?(#[\w-]+)?$/, baseURL + '/docs/resources/$1$2'],
            [/^(?:\/docs\/providers\/[\w-]+|[.]{2})\/d\/([\w-]+)\.html(?:\.markdown)?(#[\w-]+)?$/, baseURL + '/docs/data-sources/$1$2'],
            [/^(?:[.]{2}\/)?(?:docs\/)?(resources|data-sources|guides)\/([\w-]+)\.md(?:\.markdown)?(#[\w-]+)?$/, baseURL + '/docs/$1/$2$3'],
            [/^\/(docs)\/(configuration|commands)\/(.+)\.html(#[\w-]+)?$/, 'https://terraform.io/$1/$2/$3$4']
        ];
        const tfLinks = [[/^(?:https:\/\/registry.terraform.io\/providers)\/([\w-]+)\/([\w-]+)\/([\w.]+)\/docs\/(.+)$/, '/providers/$1/$2/$3/docs/$4']];
        if (categoryURL !== baseURL) {
            interLinks.push([/^([\w-]+)\.md(?:\.markdown)?(#[\w-]+)?$/, categoryURL + '/$1$2']);
        }
        if (typeof href === 'string') {
            const exp = href.match(/^https?:\/\//) ? tfLinks : interLinks;
            for (const d of exp) {
                if (href.match(d[0])) {
                    newHref = newHref.replace(d[0], d[1]);

                    break;
                }
            }
        }
        return (
            <a href={newHref} {...rest}>
                {children}
            </a>
        );
    };

    return (
        <Row id="doc-content" key={`doc-content-${doc.id}`} ref={ref}>
            {descriptionRef.current && (
                <Helmet>
                    <meta
                        name="description"
                        content={descriptionRef.current}
                    />
                </Helmet>
            )}
            {!!content && (
                <Markdown key={`docs-for-${doc.id}`} override={['headings', 'alerts', { a: linkOverride }]}>
                    {content}
                </Markdown>
            )}
            {!content && <div className="text-center">Loading...</div>}
            {doc.attributes.truncated && (
                <p className="alert alert-warning mt-3">
          This page has been truncated due to its length, visit <a href={`${sourceBaseURL}/${doc.attributes.path}`}>GitHub</a> to see the original
          file.
                </p>
            )}
        </Row>
    );
};

export default DocContent;
