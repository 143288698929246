import { Col, Row } from 'react-bootstrap';
import React, { FC } from 'react';
import { ModuleVersionResponseDataType } from './types';

type DependenciesPropsType = {
    readonly subModuleProviderDependencyIds: number[],
    readonly subModuleDependencyIds: number[],
    readonly moduleVersionData: ModuleVersionResponseDataType | null;
}

export const Dependencies: FC<DependenciesPropsType> = ({
    subModuleProviderDependencyIds,
    subModuleDependencyIds,
    moduleVersionData,
}) => {
    const hasDependencies = subModuleProviderDependencyIds.length + subModuleDependencyIds.length > 0;
    if (hasDependencies) {
        const pDeps = [],
            mDeps = [],
            relationships = moduleVersionData ? moduleVersionData.included : null;
        ;

        if (relationships) {
            for (const key in subModuleProviderDependencyIds) {
                const id = subModuleProviderDependencyIds[key];
                if (relationships.providerDependencies[id]) {
                    pDeps.push(relationships.providerDependencies[id]);
                }
            }
            for (const key in subModuleDependencyIds) {
                const id = subModuleDependencyIds[key];
                if (relationships.dependencies[id]) {
                    mDeps.push(relationships.dependencies[id]);
                }
            }

            return (
                <Col>
                    <Row className="pb-3">
                        <Col lg={12} className="h3">
                            Module dependencies
                        </Col>
                        <Col lg={12}>
                            <i>
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                Dependencies are external modules that this module references. A module is considered
                                external if it isn`t within the same
                                repository.
                            </i>
                        </Col>
                        <Col lg={12} hidden={mDeps.length !== 0} className="fw-bold pt-1">
                            This module has no external module dependencies
                        </Col>
                        {mDeps.length > 0 &&
                            mDeps.map(function (md) {
                                return (
                                    <Row key={`module-dependency-${md.id}`} className="mt-3">
                                        <Col lg={12} className="pb-1">
                                            <span className="fw-bold">
                                                {md.attributes.name} ({md.attributes.version}):
                                            </span>{' '}
                                            <code>{md.attributes.source}</code>
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Row>
                    <Row hidden={pDeps.length === 0}>
                        <Col lg={12} className="h3">
                            Provider dependencies
                        </Col>
                        <Col lg={12}>
                            <i>
                                Providers are Terraform plugins that will be automatically installed during <code>terraform
                                init</code> if available on the
                                Terraform Registry.
                            </i>
                        </Col>
                        <Col lg={12} hidden={pDeps.length !== 0} className="fw-bold pt-1">
                            This module has no provider dependencies.
                        </Col>
                        {pDeps.length > 0 &&
                            pDeps.map(function (pd) {
                                return (
                                    <Row key={`provider-dependency-${pd.id}`} className="mt-3">
                                        <Col lg={12} className="pb-1">
                                            <span className="fw-bold">{pd.attributes.name}</span> (
                                            <i>
                                                {pd.attributes.namespace}/{pd.attributes.name}
                                            </i>
                                            ) <code>{pd.attributes.version}</code>
                                        </Col>
                                    </Row>
                                );
                            })}
                    </Row>
                </Col>
            );
        }
    }

    return <p>This module has no dependencies.</p>;
};
