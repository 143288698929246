import { SetURLSearchParams } from 'react-router-dom';

import {
    ModuleListActionTypes,
    ModuleListFilterByProviderState,
    ModuleListFilterQueryState,
    SET_MODULE_LIST_FILTER_BY_PROVIDER,
    SET_MODULE_LIST_FILTER_QUERY,
    SET_SITE_THEME
} from './actionTypes';
import { ThemeState } from './reducers/theme';

export function setModuleListFilterByProvider(filter: ModuleListFilterByProviderState, setUrlHook?: SetURLSearchParams): ModuleListActionTypes {
    if (setUrlHook) {
        setUrlHook((params) => {
            if (!filter.provider) {
                params.delete('provider');
            } else {
                params.set('provider', filter.provider);
            }
            return params;
        });
    }
    return {
        type: SET_MODULE_LIST_FILTER_BY_PROVIDER,
        payload: filter
    };
}

export function setModuleListFilterQuery(filter: ModuleListFilterQueryState, setUrlHook?: SetURLSearchParams): ModuleListActionTypes {
    if (setUrlHook) {
        setUrlHook((params) => {
            if (!filter.query) {
                params.delete('query');
            } else {
                params.set('query', filter.query);
            }
            return params;
        });
    }
    return {
        type: SET_MODULE_LIST_FILTER_QUERY,
        payload: filter
    };
}

export function setSiteTheme(theme: ThemeState) {
    return {
        type: SET_SITE_THEME,
        payload: theme
    };
}
