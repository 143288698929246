export enum FavoriteTypeEnum {
    provider = 'provider',
    module = 'module',
}

export type FavoriteListType = {
    id: number;
    name: string;
    type: FavoriteTypeEnum;
    items: number[];
}

export type FavoritesStateType = {
    autoIncrement: number;
    version: number;
    favorites: FavoriteListType[];
}
