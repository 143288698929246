export const SET_MODULE_LIST_FILTER_BY_PROVIDER = 'SET_MODULE_LIST_FILTER_BY_PROVIDER';
export const SET_MODULE_LIST_FILTER_QUERY = 'SET_MODULE_LIST_FILTER_QUERY';

export const SET_SITE_THEME = 'SET_SITE_THEME';

export type ModuleListFilterByProviderState = {
    provider: null | string;
};

export type SetModuleListFilterByProviderAction = {
    type: typeof SET_MODULE_LIST_FILTER_BY_PROVIDER;
    payload: ModuleListFilterByProviderState;
};

export function isSetModuleListFilterByProviderAction(action: ModuleListActionTypes): action is SetModuleListFilterByProviderAction {
    return action.type === SET_MODULE_LIST_FILTER_BY_PROVIDER;
}

export type ModuleListFilterQueryState = {
    query: null | string;
};

export type setModuleListFilterQueryAction = {
    type: typeof SET_MODULE_LIST_FILTER_QUERY;
    payload: ModuleListFilterQueryState;
};

export function isSetModuleListFilterQueryAction(action: ModuleListActionTypes): action is setModuleListFilterQueryAction {
    return action.type === SET_MODULE_LIST_FILTER_QUERY;
}

export type ModuleListActionTypes = SetModuleListFilterByProviderAction | setModuleListFilterQueryAction;
