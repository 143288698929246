import { Col, Row } from 'react-bootstrap';
import Markdown from '../Markdown';
import React, { FC } from 'react';
import { ModuleVersionResponseDataType } from './types';

type OutputPropsType = {
    readonly subModuleOutputIds: number[];
    readonly moduleVersionData: ModuleVersionResponseDataType | null;
}
export const Outputs: FC<OutputPropsType> = ({ subModuleOutputIds, moduleVersionData }) => {
    if (subModuleOutputIds.length > 0) {
        const outputs = [],
            relationships = moduleVersionData ? moduleVersionData.included : null;

        if (relationships) {
            for (const key in subModuleOutputIds) {
                const id = subModuleOutputIds[key];
                if (relationships.outputs[id]) {
                    outputs.push(relationships.outputs[id]);
                }
            }

            return (
                <Row hidden={outputs.length === 0} className="pb-4">
                    {outputs.map(function (output) {
                        return (
                            <Row key={`output-${output.id}`} className="mt-3">
                                <Col lg={12} className="fw-bold">
                                    {output.attributes.name}
                                </Col>
                                <Col lg={12}>
                                    <Markdown>{output.attributes.description}</Markdown>
                                </Col>
                            </Row>
                        );
                    })}
                </Row>
            );
        }
    }
    return <p>This module has no output variables.</p>;
};
