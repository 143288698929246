import React, { FC, useMemo } from 'react';
import { Button } from 'react-bootstrap';

import './styles.scss';
import {
    BadgeGroupEnum,
    BadgeGroupMapType,
    badgesForSubjectMap,
    BadgeSubject,
    BadgeType, budgesFilterGroupNames,
    getBadgesConfig
} from './types';
import { SingleBadgeFilter } from './singleBudgeFilter';
import { MultiBadgeFilter } from './multiBudgeFilter';

type BadgesFilterPropsType = {
    readonly subject: BadgeSubject;
    readonly selectedBadgeTypes: string[];
    readonly onChange: (budges: string[]) => void;
}

type GroupedBadges = {
    title: string;
    badges: BadgeType[]
};

type GroupedBadgesMapType = {
    [key in BadgeGroupEnum]: GroupedBadges;
};

export const BadgesFilter: FC<BadgesFilterPropsType> = ({ subject, selectedBadgeTypes, onChange }) => {
    const resetFilterHandler = () => {
        onChange([]);
    }

    const badgesConfig = getBadgesConfig(subject);
    const allowedBadges = badgesForSubjectMap[subject];

    const groupedBadges = useMemo(() => {
        const groupedBadgesConfig: GroupedBadges[] = [];
        const badgeGroupMap: BadgeGroupMapType = {};

        for (const badge of badgesConfig) {
            if (allowedBadges.includes(badge.type)) {
                const index = badgeGroupMap[badge.group];

                if (index !== undefined) {
                    groupedBadgesConfig[index].badges.push(badge);
                } else {
                    badgeGroupMap[badge.group] = groupedBadgesConfig.length;

                    groupedBadgesConfig.push({
                        title: budgesFilterGroupNames[badge.group] || badge.title,
                        badges: [badge],
                    });
                }
            }
        }

        return groupedBadgesConfig;
    }, [badgesConfig, allowedBadges]);

    const removeBadgesWithSameGroup = (badgeTypes: string[], badgeType: string) => {
        const badge = badgesConfig.find((someBadge) => someBadge.type === badgeType);

        if (!badge) return [...badgeTypes];

        const groupOfBadgeTypes = badgesConfig
            .filter((someBadge) => someBadge.group === badge.group)
            .map((someBadge) => someBadge.type as string);

        return badgeTypes.filter((type) => !groupOfBadgeTypes.includes(type));
    };

    const onChangeHandler = (badgeType: string, selected: boolean) => {
        const foundIndex = selectedBadgeTypes.indexOf(badgeType);

        if (selected && foundIndex === -1) {
            const badgeTypes = removeBadgesWithSameGroup(selectedBadgeTypes, badgeType);

            onChange([...badgeTypes, badgeType]);
        }

        if (!selected && foundIndex > -1) {
            const tempSelected = [...selectedBadgeTypes];

            tempSelected.splice(foundIndex, 1);

            onChange(tempSelected);
        }
    };

    return (
        <div className="badges-filter">
            <div className="filter-head">
                <div className="text-secondary">
                    By badges
                </div>
                <div>
                    { selectedBadgeTypes.length > 0 && (
                        <Button
                            variant="link"
                            onClick={resetFilterHandler}
                            className="btn border-0 text-decoration-none"
                        >
                            Clear
                        </Button>
                    )}
                </div>
            </div>
            <div className="filter-body">
                {groupedBadges.map((group) => (
                    group.badges.length > 1
                        ? (
                            <MultiBadgeFilter
                                key={group.title}
                                title={group.title}
                                badges={group.badges}
                                selected={group.badges.filter((badge) => selectedBadgeTypes.includes(badge.type))}
                                onSelect={(badge, selected) => onChangeHandler(badge.type, selected)}
                            />
                        )
                        : (
                            <SingleBadgeFilter
                                key={group.title}
                                badge={group.badges[0]}
                                selected={selectedBadgeTypes.includes(group.badges[0].type)}
                                onSelect={(selected) => onChangeHandler(group.badges[0].type, selected)}
                            />
                        )
                ))}
            </div>
        </div>
    );
}
