import React from 'react';
import { List } from './emojiList';

const delimiterRegex = /(:[\w\-+]+:)/g;

export const find = (text: string) => text.match(delimiterRegex) || [];

export const exist = (emojiId: string) => !!List[emojiId];

export const parse = function (text: string) {
    const trimmed = text.trim().split(' ');

    if (trimmed[0] === '') {
        return;
    }
    const elements = trimmed.map((chunk, index) => {
        if (chunk.match(delimiterRegex)) {
            const name = chunk.replace(/:/g, '');
            const classNames = ['gh-emoji', `gh-emoji-${name}`];

            if (!exist(name)) {
                return {
                    key: index,
                    type: 'text',
                    text: chunk
                };
            }

            const imageSrc = List[name];
            const imageClass = classNames.join(' ');
            const imageAlt = name;

            return {
                key: index,
                type: 'img',
                src: imageSrc,
                className: imageClass,
                alt: imageAlt
            };
        }

        return {
            key: index,
            type: 'text',
            text: chunk
        };
    });

    return (
        <span>
            {elements.map((element) => {
                if (element.type === 'img') {
                    return (
                        <img
                            key={element.key}
                            src={element.src}
                            className={element.className}
                            alt={element.alt}
                            style={{
                                width: 14,
                                height: 14,
                                float: 'left',
                                marginRight: 4,
                                marginTop: 5
                            }}
                        />
                    );
                } else {
                    return <span key={element.key}>{element.text} </span>;
                }
            })}
        </span>
    );
};

export default parse;
