import React, { FC, Fragment } from 'react';
import escapeRegExp from 'lodash.escaperegexp';

type HighlightPropsType = {
    readonly text: string;
    readonly searchString: string;
    readonly capitalize?: boolean;
}

export const Highlight: FC<HighlightPropsType> = ({ text, searchString, capitalize = false }) => {

    if (!searchString) {
        return <span className={capitalize ? "text-capitalize" : undefined}>{text}</span>;
    }

    const chunks = text
        .split(new RegExp(`(${escapeRegExp(searchString)})`, 'gi'))
        .filter((chunk) => chunk);

    return (
        <span>
            {chunks.map((chunk, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={`${chunk}-${index}`} className={index === 0 && capitalize ? "text-capitalize" : undefined}>
                    {chunk.toLowerCase() === searchString.toLowerCase()
                        ? (
                            <mark>
                                {chunk}
                            </mark>
                        )
                        : <>{chunk}</>
                    }
                </span>
            ))}
        </span>
    );
};
