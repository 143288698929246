import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FavoriteCount } from './Favorite/favoriteCount';
import { FavoriteContext } from './Favorite/favoriteContext';

export interface PageHeaderProps extends React.PropsWithChildren {
    readonly title: string;
    readonly description?: string;
    readonly favoriteListId?: number;
    readonly showFavoriteOnly?: boolean;
    readonly onFavoriteClick?: () => void;
}

const emptyFn = () => null;

export const PageHeader: React.FC<PageHeaderProps> = ({
    title,
    description,
    children,
}) => (
    <Row>
        <Col className="rounded-pane-top bg-gradient-2">
            <Row>
                <Col>
                    <h1>{title}</h1>
                </Col>
            </Row>
            <p className="text-secondary mb-0" hidden={!!description && !!children}>
                {children ?? description}
            </p>
        </Col>
    </Row>
);


export default PageHeader;
