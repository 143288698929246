import React, { FC } from 'react';
import { BadgeType } from './types';

import './styles.scss';

type BadgeIconPropsType = {
    readonly badge: BadgeType;
}

export const BadgeIcon: FC<BadgeIconPropsType> = ({ badge }) => (
    <div className={`badge-icon ${badge.icon}`} />
);
