import React, { useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {RawLink} from '../RawLink';
import { DocCategory, DocSubcategory, OverviewPropsType, ProviderDocumentationResourceType } from './types';
import CategoryNode from './categoryNode';
import DocContent from './docContent';
import { DocumentSearch } from './documentSearch';
import { adjustResourceName } from './utils';
import { Highlight } from './highlight';

const compare = (name: string, query: string) => (
    name.toLowerCase().includes(query.toLowerCase())
);

const filterDocCategories = (items: DocSubcategory[], query: string, providerName: string) => {
    if (!query) {
        return items;
    }

    const result: DocSubcategory[] = [];

    items.forEach((item) => {
        let filteredItems:  DocCategory[] = [];

        if (compare(item.title, query)) {
            filteredItems = item.items;
        } else {
            item.items.forEach(
                (subCategory) => {

                    const filteredSubCategoryItems = !compare(subCategory.title, query)
                        ? subCategory.items.filter(
                            (provider) => compare(
                                provider.attributes.category !== 'guides'
                                    ? adjustResourceName(provider.attributes.title, providerName)
                                    : provider.attributes.title,
                                query,
                            )
                        )
                        : subCategory.items;

                    if (filteredSubCategoryItems.length > 0) {
                        filteredItems.push({ title: subCategory.title, items: filteredSubCategoryItems });
                    }
                }
            );
        }

        if (filteredItems.length > 0) {
            result.push({ title: item.title, items: filteredItems });
        }
    });

    return result;
};

export const Overview: React.FC<OverviewPropsType> = ({
    onComplete,
    providerVersion,
    provider,
    namespace,
    name,
    version,
    providerDocsTree,
    providerDocumentation,
    onSelectDoc,
}) => {
    const ref = useRef(null);
    const navigate = useNavigate();
    const [search, setSearch] = useState('');

    const filteredProviderDocsTree = useMemo(() => (providerDocsTree ? {
        ...providerDocsTree,
        root: providerDocsTree.root && compare(providerDocsTree.root.attributes.title, search) ? providerDocsTree.root : undefined,
        guides: filterDocCategories(providerDocsTree.guides, search, provider.attributes.name),
        items: filterDocCategories(providerDocsTree.items, search, provider.attributes.name),
    } : null), [search, providerDocsTree]);

    const getDocUrl = (doc: ProviderDocumentationResourceType) => {
        if (providerDocsTree && !!name && !!version && !!namespace) {
            let URL;

            URL = `/providers/` + encodeURIComponent(namespace) + `/` + encodeURIComponent(name) + `/` + encodeURIComponent(version);
            if (doc !== providerDocsTree.root) {
                URL += `/docs/${doc.attributes.category}/${doc.attributes.slug}`;
            }

            return URL;
        }

        return ''
    }

    const setSelectedDoc = (doc: ProviderDocumentationResourceType) => {
        if (providerDocsTree && !!name && !!version && !!namespace) {

            onSelectDoc(doc);
            navigate(getDocUrl(doc));
        }
    };

    const onSearchHandler = (value: string) => {
        setSearch(value);
    };
    
    return (
        <Row ref={ref}>
            <Col lg={3} className="px-0 sticky-lg-top vh-100 pt-2 mb-4 overflow-auto document-search-container">
                {providerDocsTree && providerDocsTree.root && (
                    <DocumentSearch
                        onSearch={onSearchHandler}
                    />
                )}
                <ul className="overview nav flex-column nav-link text-break pt-0">
                    {filteredProviderDocsTree && (
                        <>
                            {filteredProviderDocsTree.root && (
                                <li key={`doc-${filteredProviderDocsTree.root.id}`}>
                                    <RawLink
                                        href={getDocUrl(filteredProviderDocsTree.root)}
                                        className={`d-flex text-capitalize py-2 ps-2 ${
                                            !!filteredProviderDocsTree.selected && filteredProviderDocsTree.selected.id === filteredProviderDocsTree.root.id ? 'active' : ''
                                        }`}
                                        onClick={() => {
                                            if (filteredProviderDocsTree.root) {
                                                setSelectedDoc(filteredProviderDocsTree.root);
                                            }
                                        }}
                                    >
                                        <Highlight text="Overview" searchString={search} />
                                    </RawLink>
                                </li>
                            )}

                            {filteredProviderDocsTree.guides.map((sub) => (
                                <CategoryNode
                                    key={`cat-guides-node-${sub.title}`}
                                    selected={filteredProviderDocsTree.selected}
                                    providerName={provider.attributes.name}
                                    selectedCat={filteredProviderDocsTree.selectedCat}
                                    selectedSub={filteredProviderDocsTree.selectedSub}
                                    expanded={search !== ''}
                                    highlightedText={search}
                                    selectDoc={setSelectedDoc}
                                    getDocUrl={getDocUrl}
                                    sub={sub}
                                />
                            ))}
                            {filteredProviderDocsTree.items.map((sub) => (
                                <CategoryNode
                                    key={`cat-items-node-${sub.title}`}
                                    selected={filteredProviderDocsTree.selected}
                                    providerName={provider.attributes.name}
                                    selectedCat={filteredProviderDocsTree.selectedCat}
                                    selectedSub={filteredProviderDocsTree.selectedSub}
                                    expanded={search !== ''}
                                    highlightedText={search}
                                    selectDoc={setSelectedDoc}
                                    getDocUrl={getDocUrl}
                                    sub={sub}
                                />
                            ))}

                            {providerDocsTree && providerDocsTree.root
                                && !filteredProviderDocsTree.root
                                && !filteredProviderDocsTree.items.length
                                && !filteredProviderDocsTree.guides.length
                                && (
                                    <span className="no-result">No results found</span>
                                )
                            }
                        </>
                    )}
                </ul>
            </Col>

            <Col lg={9}>
                {!!providerDocsTree && !!providerDocsTree.selected && (
                    <DocContent
                        doc={providerDocsTree.selected}
                        onComplete={onComplete}
                        sourceBaseURL={`${provider.attributes.source}/blob/${providerVersion.attributes.tag}`}
                        provider={provider}
                        providerDocumentation={providerDocumentation}
                    />
                )}
            </Col>

            {!!providerDocsTree && !providerDocsTree.root && (
                <Col className="text-center">
                    <h1>Documentation Unavailable</h1>
                    <Row>
                        <Col>
                            This version of the <span className="fw-bold">{provider.attributes.name}</span> provider doesn&apos;t have any documentation available.
                            <hr />
                            <p>
                                There might be documentation in a different format in the provider&apos;s{' '}
                                <a href={`${provider.attributes.source}/tree/${providerVersion.attributes.tag}`}>GitHub</a> repository. If this is a new version and
                                the previous documentation has disappeared, there might have been a problem with the release process; you can contact the provider
                                owner for more information.
                            </p>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
};

export default Overview;
