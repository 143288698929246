import React from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import moment from 'moment/moment';
import { LiaStroopwafelSolid } from 'react-icons/lia';

import API from '../../api';
import { recursiveToCamel, toHumanString } from '../common';
import {
    ProviderDownloadsResourceType,
    ProviderItemType,
    ProviderVersionItemType,
    SourceInfoResourceType,
    SourceParticipationResourceType
} from '../resourceTypes';

import { TopWidgetProps } from './types';
import { Link } from 'react-router-dom';

type ProviderWithIncludes = {
    included: {
        latestVersion: ProviderVersionItemType;
        sourceInfo?: SourceInfoResourceType;
        providerDownloads?: ProviderDownloadsResourceType;
        sourceParticipation?: SourceParticipationResourceType;
    };
} & ProviderItemType;

type TopProvidersStateType = ProviderWithIncludes[];

type RefType = {
    'provider-versions': { [key: number]: ProviderVersionItemType };
    'provider-downloads': { [key: number]: ProviderDownloadsResourceType };
    'source-info': { [key: number]: SourceInfoResourceType };
    'source-participation': { [key: number]: SourceParticipationResourceType };
};

export const TopProviders: React.FC<TopWidgetProps> = ({
    type,
    providerIds,
    limit = 10,
    short = false,
    showSeeAll = false,
}) => {
    const [data, setData] = React.useState<TopProvidersStateType>([]);

    const headers = {
        'top-downloads': 'The Most Popular Providers by Monthly Downloads',
        'most-recently-published': 'The Most Recently Published Providers',
        'most-subscribed': 'The Most Subscribed Providers',
        'most-participated': 'Providers with the Most Commits',
        'trending-stars': 'Trending Providers',
        'most-closed-pulls': 'Providers with the Most Closed Pull Requests',
        'favorite': `Favorite Providers`,
    };
    const subtitles = {
        'top-downloads': 'Providers with the largest number of downloads in the current month.',
        'most-recently-published': 'The latest providers published in reverse chronological order.',
        'most-subscribed': 'Providers with the largest number of is Github Watchers.',
        'most-participated': 'Providers with the highest number of commits in the last 30 days.',
        'trending-stars': 'Providers with the largest increase in Github Stargazers last week.',
        'most-closed-pulls': 'Providers with the highest number of closed pull requests in the last week.',
        'favorite': '',
    };
    const titles = {
        'top-downloads': 'Downloads this month',
        'most-recently-published': 'Last publish',
        'most-subscribed': 'Number of watchers',
        'most-participated': 'Number of commits in the last 30 days',
        'trending-stars': 'Stars last week',
        'most-closed-pulls': 'Number of closed pull requests over the last week',
        'favorite': '',
    };
    const URLs = {
        'top-downloads': '/providers?include=latest-version,provider-downloads&sort=-provider-downloads.month',
        'most-recently-published': '/providers?include=latest-version&sort=-latest-version.published-at',
        'most-subscribed': '/providers?include=latest-version,source-info&sort=-source-info.watchers-count',
        'most-participated': '/providers?filter[source-info.is-fork]=false&include=latest-version,source-participation&sort=-source-participation.month',
        'trending-stars': '/providers?include=latest-version,source-info&sort=-source-info.stargazers-weekly-increase',
        'most-closed-pulls': '/providers?include=latest-version,source-info&sort=-source-info.closed-pulls-weekly-increase',
        'favorite': `/providers?include=latest-version,provider-downloads&filter[id]=${(providerIds || []).join(',')}&sort=${encodeURIComponent('-downloads')}`,
    };
    const displayValues = {
        'top-downloads': (i: ProviderWithIncludes) => toHumanString(i.included.providerDownloads?.attributes.month),
        'most-recently-published': (i: ProviderWithIncludes) => moment(i.included.latestVersion.attributes.publishedAt, 'YYYY-MM-DDThh:mm:ssZ').fromNow(),
        'most-subscribed': (i: ProviderWithIncludes) => toHumanString(i.included.sourceInfo?.attributes.watchersCount),
        'most-participated': (i: ProviderWithIncludes) => toHumanString(i.included.sourceParticipation?.attributes.month),
        'trending-stars': (i: ProviderWithIncludes) =>
            ((i.included.sourceInfo?.attributes.stargazersWeeklyIncrease ?? 0) > 0 ? '+' : '') +
            `${toHumanString(i.included.sourceInfo?.attributes.stargazersWeeklyIncrease)}`,
        'most-closed-pulls': (i: ProviderWithIncludes) =>
            ((i.included.sourceInfo?.attributes.closedPullsWeeklyIncrease ?? 0) > 0 ? '+' : '') +
            `${toHumanString(i.included.sourceInfo?.attributes.closedPullsWeeklyIncrease)}`,
        'favorite': null,
    };
    const header = headers[type];
    const subtitle = subtitles[type];
    const URL = `${URLs[type]}&limit=${limit}`;

    const transformData = (d: any) => {
        const result: TopProvidersStateType = [];

        const reference: RefType = {
            'provider-versions': {},
            'provider-downloads': {},
            'source-info': {},
            'source-participation': {}
        };

        for (const i of d.included) {
            if (i.type === 'provider-versions' || i.type === 'source-info' || i.type === 'provider-downloads' || i.type === 'source-participation') {
                reference[i.type as keyof RefType][i.id] = i;
            }
        }
        for (const i of d.data) {
            i.included = {};
            i.included.latestVersion = reference['provider-versions'][i.relationships.latestVersion.data.id];
            if (!!i.relationships.providerDownloads.data) {
                i.included.providerDownloads = reference['provider-downloads'][i.relationships.providerDownloads.data.id];
            }
            if (!!i.relationships.sourceInfo.data) {
                i.included.sourceInfo = reference['source-info'][i.relationships.sourceInfo.data.id];
            }
            if (!!i.relationships.sourceParticipation.data) {
                i.included.sourceParticipation = reference['source-participation'][i.relationships.sourceParticipation.data.id];
            }
            if (
                (type == 'trending-stars' && !i.included.sourceInfo.attributes.stargazersWeeklyIncrease) ||
                (type == 'most-closed-pulls' && !i.included.sourceInfo.attributes.closedPullsWeeklyIncrease)
            ) {
                continue;
            }
            result.push(i);
        }

        return result;
    };

    React.useEffect(() => {
        API.get(URL)
            .then((response) => {
                setData(transformData(recursiveToCamel(response.data)));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getDisplayValues = displayValues[type];

    return (
        <div className="insight" hidden={data.length == 0}>
            <Row className="rounded-pane pane-shadow">
                <Row>
                    <div className="widget-header-container">
                        <h5 className="px-2 fw-bold">
                            <LiaStroopwafelSolid />
                            {header}
                        </h5>

                        { showSeeAll && type === 'favorite' && (providerIds || []).length > limit && (
                            <Link to="/providers?favorite=1" className="nav-link p-2">
                                See all
                            </Link>
                        )}
                    </div>
                    {subtitle && (
                        <div>
                            <p className="px-2 text-secondary">{subtitle}</p>
                        </div>
                    )}
                </Row>
                <ListGroup as="div">
                    {data.map((item) => (
                        <ListGroup.Item
                            key={`provider-${type}-${item.id}`}
                            as="a"
                            className="link d-flex justify-content-between align-items-start pointer"
                            action
                            href={`/providers/${item.attributes.fullName}/latest`}
                            title="Click to open the provider"
                        >
                            <Col className="me-1 overflow-hidden">
                                <span className="fw-bold text-nowrap">
                                    {item.attributes.namespace} / {item.attributes.name}
                                </span>{' '}
                                <div className={`text-secondary text-truncate-${(short ? '1' : '2')}`}>
                                    {item.attributes.description !== ''
                                        ? item.attributes.description
                                        : item.included.latestVersion.attributes.description !== ''
                                            ? item.included.latestVersion.attributes.description
                                            : ''}
                                </div>
                            </Col>
                            {getDisplayValues && titles[type] && (
                                <span className="text-end text-nowrap text-secondary" title={titles[type]}>
                                    {getDisplayValues(item)}
                                </span>
                            )}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Row>
        </div>
    );
};

export default TopProviders;
