export enum BadgeEnum {
    downloads100 = 'DOWNLOADS_100',
    downloads1k = 'DOWNLOADS_1000',
    downloads10k = 'DOWNLOADS_10000',
    downloads100k = 'DOWNLOADS_100000',
    downloads1m = 'DOWNLOADS_1000000',
    stars100 = 'STARS_100',
    stars1k = 'STARS_1000',
    stars10k = 'STARS_10000',
    stars100k = 'STARS_100000',
    forked10 = 'FORKS_10',
    forked100 = 'FORKS_100',
    forked1k = 'FORKS_1000',
    abandoned = 'ABANDONED',
    loneWolf = 'LONE_WOLF',
    ridinSolo = 'RIDIN_SOLO',
    keepItRegular = 'KEEPING_IT_REGULAR',
    risenFromDead = 'RISEN_FROM_DEAD',
}

export enum BadgeGroupEnum {
    downloads,
    stars,
    forked,
    abandoned,
    loneWolf,
    ridinSolo,
    keepItRegular,
    risenFromDead,
}

export type BadgeType = {
    type: BadgeEnum;
    group: BadgeGroupEnum;
    icon: string;
    level: number;
    title: string;
    shortLabel?: string;
    description: string;
}

export type BadgeSubject = 'provider' | 'module' | 'author';

type SubjectMapType = {
    [key in BadgeGroupEnum]?: {
        [subject in BadgeSubject]?: string;
    }
}

const subjectMap: SubjectMapType = {
    [BadgeGroupEnum.downloads]: {
        author: 'author has modules and providers',
    },
}

export const getBadgesConfig = (subject: BadgeSubject): BadgeType[] => {
    const subjectLabel = (
        subjectMap[BadgeGroupEnum.downloads] && subjectMap[BadgeGroupEnum.downloads][subject]
    ) || subject;

    return (
        [
            {
                type: BadgeEnum.downloads100,
                group: BadgeGroupEnum.downloads,
                icon: 'downloads',
                level: 1,
                title: '100 Downloads in Month',
                shortLabel: '100',
                description: `100 Club: The ${subjectLabel} has been downloaded 100 times in a month.`,
            }, {
                type: BadgeEnum.downloads1k,
                group: BadgeGroupEnum.downloads,
                icon: 'downloads',
                level: 2,
                title: '1k Downloads in Month',
                shortLabel: '1k',
                description: `1 000 Club: The ${subjectLabel} has been downloaded 1 000 times in a month.`,
            }, {
                type: BadgeEnum.downloads10k,
                group: BadgeGroupEnum.downloads,
                icon: 'downloads',
                level: 3,
                title: '10k Downloads in Month',
                shortLabel: '10k',
                description: `10 000 Club: The ${subjectLabel} has been downloaded 10 000 times in a month.`,
            }, {
                type: BadgeEnum.downloads100k,
                group: BadgeGroupEnum.downloads,
                icon: 'downloads',
                level: 4,
                title: '100k Downloads in Month',
                shortLabel: '100k',
                description: `100 000 Club: The ${subjectLabel} has been downloaded 100 000 times in a month.`,
            }, {
                type: BadgeEnum.downloads1m,
                group: BadgeGroupEnum.downloads,
                icon: 'downloads',
                level: 5,
                title: '1m Downloads in Month',
                shortLabel: '1M',
                description: `1 000 000 Club: The ${subjectLabel} has been downloaded 1 000 000 times in a month.`,
            }, {
                type: BadgeEnum.stars100,
                group: BadgeGroupEnum.stars,
                icon: 'stars',
                level: 1,
                title: '100 Github Stars',
                shortLabel: '100',
                description: `The ${subject} has been starred in GitHub 100 times, all-time.`,
            }, {
                type: BadgeEnum.stars1k,
                group: BadgeGroupEnum.stars,
                icon: 'stars',
                level: 2,
                title: '1k Github Stars',
                shortLabel: '1k',
                description: `The ${subject} has been starred in GitHub 1 000 times, all-time.`,
            }, {
                type: BadgeEnum.stars10k,
                group: BadgeGroupEnum.stars,
                icon: 'stars',
                level: 3,
                title: '10k Github Stars',
                shortLabel: '10k',
                description: `The ${subject} has been starred in GitHub 10 000 times, all-time.`,
            }, {
                type: BadgeEnum.stars100k,
                group: BadgeGroupEnum.stars,
                icon: 'stars',
                level: 4,
                title: '100k Github Stars',
                shortLabel: '100k',
                description: `The ${subject} has been starred in GitHub 100 000 times, all-time.`,
            }, {
                type: BadgeEnum.forked10,
                group: BadgeGroupEnum.forked,
                icon: 'forked',
                level: 1,
                title: 'Forked 10 times',
                shortLabel: '10',
                description: `The ${subject} has been forked 10 times.`,
            }, {
                type: BadgeEnum.forked100,
                group: BadgeGroupEnum.forked,
                icon: 'forked',
                level: 2,
                title: 'Forked 100 times',
                shortLabel: '100',
                description: `The ${subject} has been forked 100 times.`,
            }, {
                type: BadgeEnum.forked1k,
                group: BadgeGroupEnum.forked,
                icon: 'forked',
                level: 3,
                title: 'Forked 1k times',
                shortLabel: '1k',
                description: `The ${subject} has been forked 1 000 times.`,
            }, {
                type: BadgeEnum.abandoned,
                group: BadgeGroupEnum.abandoned,
                icon: 'abandoned',
                level: 1,
                title: 'Abandoned',
                description: 'No commits or pull requests for >1 year.',
            }, {
                type: BadgeEnum.loneWolf,
                group: BadgeGroupEnum.loneWolf,
                icon: 'oneManWolfPack',
                level: 1,
                title: 'One man wolfpack',
                description: `The ${subject} has only ever had one contributor.`,
            }, {
                type: BadgeEnum.ridinSolo,
                group: BadgeGroupEnum.ridinSolo,
                icon: 'ridinSolo',
                level: 1,
                title: 'Ridin\' Solo',
                description: `The ${subject} has only had one contributor in the last 12 months.`,
            }, {
                type: BadgeEnum.keepItRegular,
                group: BadgeGroupEnum.keepItRegular,
                icon: 'keepItRegular',
                level: 1,
                title: 'Keeping it regular',
                description: '1 or more release per month for the last 3 months. ',
            }, {
                type: BadgeEnum.risenFromDead,
                group: BadgeGroupEnum.risenFromDead,
                icon: 'risenFromDead',
                level: 1,
                title: 'Risen from dead',
                description: 'Release after no releases for at least a year',
            },
        ]);
}

export const badgesForSubjectMap: Record<BadgeSubject, BadgeEnum[]> = {
    provider: [
        BadgeEnum.downloads100,
        BadgeEnum.downloads1k,
        BadgeEnum.downloads10k,
        BadgeEnum.downloads100k,
        BadgeEnum.downloads1m,

        BadgeEnum.stars100,
        BadgeEnum.stars1k,

        BadgeEnum.forked10,
        BadgeEnum.forked100,
        BadgeEnum.forked1k,

        BadgeEnum.abandoned,
        BadgeEnum.loneWolf,
        BadgeEnum.ridinSolo,
        BadgeEnum.keepItRegular,
        BadgeEnum.risenFromDead,
    ],
    module: [
        BadgeEnum.downloads100,
        BadgeEnum.downloads1k,
        BadgeEnum.downloads10k,
        BadgeEnum.downloads100k,
        BadgeEnum.downloads1m,

        BadgeEnum.stars100,
        BadgeEnum.stars1k,

        BadgeEnum.forked10,
        BadgeEnum.forked100,
        BadgeEnum.forked1k,

        BadgeEnum.abandoned,
        BadgeEnum.loneWolf,
        BadgeEnum.ridinSolo,
        BadgeEnum.keepItRegular,
        BadgeEnum.risenFromDead,
    ],
    author: [],
}

type BudgesFilterGroupNamesType = {
    [key in BadgeGroupEnum]?: string;
}

export const budgesFilterGroupNames: BudgesFilterGroupNamesType = {
    [BadgeGroupEnum.downloads]: 'Downloads, month',
    [BadgeGroupEnum.stars]: 'Stars, times',
    [BadgeGroupEnum.forked]: 'Forked, times',
}

export type BadgeGroupMapType = {
    [key in BadgeGroupEnum]?:  number;
}
