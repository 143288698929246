import React from 'react';
import { Badge, ListGroup } from 'react-bootstrap';

import emojiParse from '../Emoji';
import type { SourceTopLabelsResourceType } from '../resourceTypes';
import { toHumanString } from '../common';

export type SourceTopLabelsProps = {
    readonly data: SourceTopLabelsResourceType;
    readonly sourceURL: string;
};
export const SourceTopLabels: React.FC<SourceTopLabelsProps> = ({ data, sourceURL }) => {
    return (
        <ListGroup as="div" className="pointer list-group-border-0">
            {data.data.map(function (props) {
                return (
                    <ListGroup.Item
                        key={props.labelName}
                        as="a"
                        action
                        href={`${sourceURL}/issues?q=label%3A%22${encodeURIComponent(props.labelName)}%22+is%3Aopen`}
                        className="link d-flex justify-content-between align-items-start"
                    >
                        <div>{emojiParse(props.labelName)}</div>
                        <Badge className="text-end" hidden={props.openIssuesCount === 0} bg="tint" pill>
                            {props.openIssuesCount ? toHumanString(props.openIssuesCount) : '0'}
                        </Badge>
                    </ListGroup.Item>
                );
            })}
        </ListGroup>
    );
};

export default SourceTopLabels;
