import React, { FC } from "react";
import { BadgeType } from './types';
import { classNames } from '../../utils/classNames';
import { BadgeFilterTitle } from './badgeFilterTitle';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type SingleBadgeFilterPropsType = {
    readonly badge: BadgeType;
    readonly selected?: boolean;
    readonly onSelect: (selected: boolean) => void;
}

export const SingleBadgeFilter: FC<SingleBadgeFilterPropsType> = ({ badge, selected = false, onSelect }) => (
    <OverlayTrigger
        flip
        placement="top"
        delay={{ show: 1000, hide: 100 }}
        overlay={(
            <Tooltip id="tooltip">
                {badge.description}
            </Tooltip>
        )}
    >
        <div
            className={classNames('badge-filter', 'single-value', 'hover', ['selected', selected])}
            onClick={() => onSelect(!selected)}
        >
            <BadgeFilterTitle badge={badge} selected={selected} />
        </div>
    </OverlayTrigger>
);
