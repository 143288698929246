import React, { FC, useMemo } from 'react';
import { ProviderVersionPlatformType } from '../resourceTypes';
import { Row, Table } from 'react-bootstrap';
import { GoVersions } from 'react-icons/go';
import { OsImage } from './osImage';

type PlatformListPropsType = {
    readonly platforms: ProviderVersionPlatformType[];
}

type OsPlatformsType = {
    os: string;
    platforms: string[];
}[];

export const PlatformList: FC<PlatformListPropsType> = ({ platforms }) => {

    const osPlatforms = useMemo(() => {
        const result: OsPlatformsType = [];

        platforms.sort(
            (itemA, itemB) => itemA.os.toLowerCase() < itemB.os.toLowerCase() ? 1 : -1
        ).forEach((item) => {
            if (result.length && item.os.toLowerCase() === result[result.length - 1].os) {
                result[result.length - 1].platforms.push(item.arch);
            } else {
                result.push({
                    os: item.os.toLowerCase(),
                    platforms: [item.arch]
                });
            }
        });

        return result;
    }, [platforms]);

    return (
        <Row>
            <Table hover responsive className="d-block w-100 d-sm-table version-table">
                <thead>
                    <tr>
                        <th>Operating system</th>
                        <th>Supported platforms</th>
                    </tr>
                </thead>
                <tbody>
                    {osPlatforms.map((platform) => (
                        <tr key={`os-${platform.os}`}>
                            <td>
                                <span><OsImage os={platform.os} /></span>
                                {platform.os}
                            </td>
                            <td>{platform.platforms.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Row>
    );
}