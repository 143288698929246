import React, { useRef } from 'react';
import rehypeStringify from 'rehype-stringify';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import { default as ReactMarkdown, Options as ReactMarkdownOptions } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism, dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FaCircleInfo } from 'react-icons/fa6';
import { GoAlertFill } from 'react-icons/go';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'react-bootstrap';

import { isDarkTheme } from '../common';

type CommonOverride = 'headings' | 'alerts';

export type OverrideCallback = {
    (p: any): React.JSX.Element;
};

export type OverrideCallbackList = {
    [name: string]: OverrideCallback;
};

export type MarkdownPropsType = {
    readonly override?: (CommonOverride | OverrideCallbackList)[];
} & ReactMarkdownOptions;

export const Markdown: React.FC<MarkdownPropsType> = ({ ...props }) => {
    const { override } = props;
    const remarkPlugins: any = [remarkParse, remarkGfm, remarkRehype, rehypeRaw, rehypeStringify];

    const flatten = (text: string, child: any): string => {
        return typeof child === 'string' ? text + child : React.Children.toArray(child.props.children).reduce(flatten, text);
    };

    const headingRenderer = (p: any) => {
        const { node, id, level, children, ...rest } = p;
        const text = React.Children.toArray(children).reduce(flatten, '');
        const slug = id ?? String(text).replace(/\/+/g, '').replace(/\W+/g, '-').replace(/^-|-$/, '').toLowerCase();
        return React.createElement(
            'h' + level,
            { id: slug, ...rest },
            <a href={`#${slug}`} className="anchor">
                {children}
            </a>
        );
    };

    const alertRenderer = (p: any) => {
        const { node, children, ...rest } = p;
        const childrenList = React.Children.toArray(children);
        if (childrenList.length > 0 && typeof childrenList[0] === 'string') {
            const m = /^([!~-])>/.exec(childrenList[0]);
            if (m) {
                let type = m[1] === '~' ? 'alert-warning' : m[1] === '!' ? 'alert-danger' : 'alert-info';
                let note,
                    exclude = -1;
                if (childrenList[1] && React.isValidElement(childrenList[1]) && childrenList[1].type === 'strong') {
                    note = childrenList[1].props.children;
                    if (type === 'alert-info' && String(note).match(/^warning/i) !== null) {
                        type = 'alert-warning';
                    }
                    exclude += 2;
                } else {
                    childrenList[0] = childrenList[0].substring(2);
                    note = 'Note';
                }
                return (
                    <div role="alert" className={`alert ${type}`}>
                        <h5 className="alert-heading">
                            <span>
                                {type === 'alert-warning' || type === 'alert-danger' ? <GoAlertFill /> : <FaCircleInfo />}
                                {note}
                            </span>
                        </h5>
                        <p {...rest}>
                            {childrenList.map((c, i) => {
                                if (i > exclude) {
                                    return c;
                                }
                            })}
                        </p>
                    </div>
                );
            }
        }
        return <p {...rest}>{children}</p>;
    };

    let components: any = {
        code: (p: any) => {
            const caption = ' Copy ';
            const buttonRef = useRef<HTMLButtonElement>(null);
            const { children, className, node, ...rest } = p;
            const match = /language-(\w+)/.exec(className || '');
            if (match && match[1] === 'terraform') {
                match[1] = 'hcl';
            }
            const onCopyHook = () => {
                if (!buttonRef.current) {
                    return;
                }
                buttonRef.current.innerHTML = 'Copied';
                buttonRef.current.classList.remove('btn-outline-secondary');
                buttonRef.current.classList.add('btn-success');
                setTimeout(() => {
                    if (buttonRef.current) {
                        buttonRef.current.innerHTML = caption;
                        buttonRef.current.classList.remove('btn-success');
                        buttonRef.current.classList.add('btn-outline-secondary');
                    }
                }, 500);
            };
            return match ? (
                <>
                    <CopyToClipboard text={String(children).replace(/\n$/, '')} onCopy={onCopyHook}>
                        <Button ref={buttonRef} variant="outline-secondary" className="btn-xsm copy-to-clipboard">
                            {caption}
                        </Button>
                    </CopyToClipboard>
                    <SyntaxHighlighter PreTag="div" language={match[1]} style={isDarkTheme() ? dracula : prism}>
                        {String(children).replace(/\n$/, '')}
                    </SyntaxHighlighter>
                </>
            ) : (
                <code {...rest} className={className}>
                    {children}
                </code>
            );
        }
    };
    if (override) {
        for (const d of override) {
            if (d === 'headings') {
                remarkPlugins.push(rehypeSlug);
                components = {
                    ...components,
                    h1: (p: any) => {
                        return headingRenderer({ ...p, level: 1 });
                    },
                    h2: (p: any) => {
                        return headingRenderer({ ...p, level: 2 });
                    },
                    h3: (p: any) => {
                        return headingRenderer({ ...p, level: 3 });
                    },
                    h4: (p: any) => {
                        return headingRenderer({ ...p, level: 4 });
                    },
                    h5: (p: any) => {
                        return headingRenderer({ ...p, level: 5 });
                    }
                };
            }
            if (d === 'alerts') {
                components = {
                    ...components,
                    p: alertRenderer
                };
            }
            if (typeof d !== 'string') {
                components = {
                    ...components,
                    ...d
                };
            }
        }
    }

    return (
        <div className="markdown">
            <ReactMarkdown remarkPlugins={remarkPlugins} components={components}>
                {props.children}
            </ReactMarkdown>
        </div>
    );
};

export default Markdown;
