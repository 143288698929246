import React, { FC } from 'react';
import { FaFreebsd, FaWindows } from 'react-icons/fa';
import { SiAlpinelinux, SiMacos, SiNetbsd, SiOpenbsd } from 'react-icons/si';
import { FcLinux } from 'react-icons/fc';
import { GrSolaris } from 'react-icons/gr';

type OsImagePropsType = {
    readonly os: string;
}

export const OsImage: FC<OsImagePropsType> = ({ os }) => (
    <span>
        {os === 'windows' && <FaWindows />}
        {os === 'linux' && <FcLinux />}
        {os === 'freebsd' && <FaFreebsd />}
        {(os === 'darwin' || os === 'macos') && <SiMacos />}
        {os === 'openbsd' && <SiOpenbsd />}
        {os === 'solaris' && <GrSolaris />}
        {os === 'alpine' && <SiAlpinelinux />}
        {os === 'netbsd' && <SiNetbsd />}
    </span>
)
