import React, { FC, useContext, useMemo } from 'react'
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { FavoriteContext } from './favoriteContext';

export type FavoritePropsType = {
    readonly itemId: number;
    readonly listId: number;
}

export const Favorite: FC<FavoritePropsType> = ({ itemId, listId } ) => {
    const { getFavoriteItems, addFavoriteItem, removeFavoriteItem } = useContext(FavoriteContext);
    const favoriteItems = getFavoriteItems(listId);

    const isFavorite = useMemo(() => favoriteItems.includes(itemId), [favoriteItems, itemId]);

    const onClickHandler:  React.MouseEventHandler<HTMLDivElement> = (e) => {
        if (isFavorite) {
            removeFavoriteItem(listId, itemId);
        } else {
            addFavoriteItem(listId, itemId)
        }

        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <div className="favorite" onClick={onClickHandler}>
            {isFavorite && (
                <>
                    <div className="hide-less-lg">Remove from favorites</div>
                    <MdFavorite />
                </>
            )}
            {!isFavorite && (
                <>
                    <div className="hide-less-lg">Add to favorites</div>
                    <MdFavoriteBorder />
                </>
            )}
        </div>
    );
}
