import React, { FC, useEffect, useState } from 'react';
import API from '../../api';
import { ProviderCardPropsType } from './index';

import { ProviderListState } from './types';
import { transformProvidersData } from './utils';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ProviderCard } from './providerCard';

type ForksListPropsType = {
    readonly providerId: number;
};

export const ForksList: FC<ForksListPropsType> = ({ providerId }) => {
    const [state, setState] = useState<ProviderListState>({
        items: [],
        page: 0,
        hasMore: true
    });

    const fetchForksData = (reset = false) => {
        const page = reset ? 0 : state.page;
        let apiURL = '/providers?include=' + encodeURIComponent('categories,latest-version,source-participation');
        apiURL += '&page=' + (page + 1);

        apiURL += `&filter[potential-fork-of]=` + encodeURIComponent(providerId);
        apiURL += '&sort=' + encodeURIComponent('-downloads');

        API.get(apiURL)
            .then((response) => {
                const data = response.data;

                setState({
                    items: reset ? transformProvidersData(data) : state.items.concat(transformProvidersData(data)),
                    page: data['meta']['pagination']['current-page'],
                    hasMore: data['meta']['pagination']['next-page'] != null
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchForksData(true);
    }, [providerId]);

    return (
        <Row className="forks-list">
            <InfiniteScroll
                dataLength={state.items ? state.items.length : 0}
                next={fetchForksData}
                hasMore={state.hasMore}
                loader={<div className="text-center">Loading...</div>}
                scrollThreshold={0.6}
            >
                {state.items.map((props: ProviderCardPropsType) => (
                    <ProviderCard key={`provider-${props.provider.id}`} {...props} />
                ))}
            </InfiniteScroll>
            <Row hidden={state.items.length !== 0 || state.hasMore} className="px-0 mx-0">
                <Col className="rounded-pane-bottom">No forks found.</Col>
            </Row>
        </Row>
    )
}
