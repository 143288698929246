import React, { useEffect } from 'react';
import TopProviders from './topProviders';
import { Col, Row } from 'react-bootstrap';
import TopModules from './topModules';
import { IconContext } from 'react-icons';

import { defaultTitle } from '../constants';

export const Insights = () => {
    useEffect(() => {
        document.title = `Insights | ${defaultTitle}`;
    }, []);

    return (
        <Row className="span-vh">
            {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
            <IconContext.Provider value={{ className: 'react-icons' }}>
                <Col lg={6} xl={6} xxl={4}>
                    <TopProviders key="providers-trending-stars" type="trending-stars" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopModules key="modules-trending-stars" type="trending-stars" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopProviders key="providers-most-participated" type="most-participated" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopModules key="modules-most-participated" type="most-participated" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopProviders key="providers-most-closed-pulls" type="most-closed-pulls" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopModules key="modules-most-closed-pulls" type="most-closed-pulls" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopProviders key="providers-most-recently-published" type="most-recently-published" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopModules key="modules-most-recently-published" type="most-recently-published" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopProviders key="providers-most-subscribed" type="most-subscribed" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopModules key="modules-most-subscribed" type="most-subscribed" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopProviders key="providers-top-downloads" type="top-downloads" />
                </Col>

                <Col lg={6} xl={6} xxl={4}>
                    <TopModules key="modules-top-downloads" type="top-downloads" />
                </Col>
            </IconContext.Provider>
        </Row>
    );
};

export default Insights;
