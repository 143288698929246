import { Col, Row } from 'react-bootstrap';
import React, { FC } from 'react';
import { ModuleVersionResponseDataType } from './types';

type ResourcesPropsType = {
    readonly subModuleResourceIds: number[],
    readonly moduleVersionData: ModuleVersionResponseDataType | null;
}

export const Resources: FC<ResourcesPropsType> = ({ subModuleResourceIds, moduleVersionData }) => {
    if (subModuleResourceIds.length > 0) {
        const resources = [],
            relationships = moduleVersionData ? moduleVersionData.included : null;

        if (relationships) {
            for (const key in subModuleResourceIds) {
                const id = subModuleResourceIds[key];
                if (relationships.resources[id]) {
                    resources.push(relationships.resources[id]);
                }
            }

            return (
                <Col>
                    <Row hidden={resources.length === 0}>
                        <Col lg={12} className="h3">
                            Resources
                        </Col>
                        <Col lg={12}>
                            <i>
                                This is the list of resources that the module may create. The module can create zero or
                                more of each of these resources depending on
                                the <code>count</code> value. The count value is determined at runtime. The goal of this
                                page is to present the types of resources
                                that may be created. This list contains all the resources this plus any submodules may
                                create. When using this module, it may create
                                fewer resources if you use a submodule.
                            </i>
                        </Col>
                        <Col lg={12} className="py-2">
                            This module defines {resources.length} resources.
                        </Col>

                        {resources.map(function (res) {
                            return (
                                <Row key={`resource-${res.id}`}>
                                    <Col lg={12} className="pb-1">
                                        <code>
                                            {res.attributes.type}/{res.attributes.name}
                                        </code>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Row>
                </Col>
            );
        }
    }
    return <p>This module has no resources.</p>;
};
