import React from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import moment from 'moment/moment';
import { PiParallelogram } from 'react-icons/pi';

import API from '../../api';
import { recursiveToCamel, toHumanString } from '../common';
import {
    ModuleDownloadsResourceType,
    ModuleVersionData,
    SourceInfoResourceType,
    SourceParticipationResourceType
} from '../resourceTypes';
import { TopWidgetProps } from './types';
import { ModuleItemType } from '../Module/types';
import { LiaStroopwafelSolid } from 'react-icons/lia';
import { Link } from 'react-router-dom';

type ModuleWithIncludes = {
    included: {
        latestVersion: ModuleVersionData;
        sourceInfo?: SourceInfoResourceType;
        moduleDownloads?: ModuleDownloadsResourceType;
        sourceParticipation?: SourceParticipationResourceType;
    };
} & ModuleItemType;

type TopModulesStateType = ModuleWithIncludes[];

type RefType = {
    'module-versions': { [key: number]: ModuleVersionData };
    'module-downloads': { [key: number]: ModuleDownloadsResourceType };
    'source-info': { [key: number]: SourceInfoResourceType };
    'source-participation': { [key: number]: SourceParticipationResourceType };
};

export const TopModules: React.FC<TopWidgetProps> = ({
    type,
    moduleIds,
    limit = 10,
    short = false,
    showSeeAll = false,
}) => {
    const [data, setData] = React.useState<TopModulesStateType>([]);

    const headers = {
        'top-downloads': 'The Most Popular Modules by Monthly Downloads',
        'most-recently-published': 'The Most Recently Published Modules',
        'most-subscribed': 'The Most Subscribed Modules',
        'most-participated': 'Modules with the Most Commits',
        'trending-stars': 'Trending Modules',
        'most-closed-pulls': 'Modules with the Most Closed Pull Requests',
        'favorite': `Favorite Modules`,
    };
    const subtitles = {
        'top-downloads': 'Modules with the largest number of downloads in the current month.',
        'most-recently-published': 'The latest modules published in reverse chronological order.',
        'most-subscribed': 'Modules with the largest number of is Github Watchers.',
        'most-participated': 'Modules with the highest number of commits in the last 30 days.',
        'trending-stars': 'Modules with the largest increase in Github Stargazers last week.',
        'most-closed-pulls': 'Modules with the highest number of closed pull requests in the last week.',
        'favorite': '',
    };
    const titles = {
        'top-downloads': 'Downloads this month',
        'most-recently-published': 'Last publish',
        'most-subscribed': 'Number of watchers',
        'most-participated': 'Number of commits in the last 30 days',
        'trending-stars': 'Stars last week',
        'most-closed-pulls': 'Number of closed pull requests over the last week',
        'favorite': '',
    };
    const URLs = {
        'top-downloads': '/modules?include=latest-version,module-downloads&sort=-module-downloads.month',
        'most-recently-published': '/modules?include=latest-version&sort=-latest-version.published-at',
        'most-subscribed': '/modules?include=latest-version,source-info&sort=-source-info.watchers-count',
        'most-participated': '/modules?filter[source-info.is-fork]=false&include=latest-version,source-participation&sort=-source-participation.month',
        'trending-stars': '/modules?include=latest-version,source-info&sort=-source-info.stargazers-weekly-increase',
        'most-closed-pulls': '/modules?include=latest-version,source-info&sort=-source-info.closed-pulls-weekly-increase',
        'favorite': `/modules?include=latest-version,module-downloads&filter[id]=${(moduleIds || []).join(',')}&sort=${encodeURIComponent('-downloads')}`,
    };
    const displayValues = {
        'top-downloads': (i: ModuleWithIncludes) => toHumanString(i.included.moduleDownloads?.attributes.month),
        'most-recently-published': (i: ModuleWithIncludes) => moment(i.included.latestVersion.attributes.publishedAt, 'YYYY-MM-DDThh:mm:ssZ').fromNow(),
        'most-subscribed': (i: ModuleWithIncludes) => toHumanString(i.included.sourceInfo?.attributes.watchersCount),
        'most-participated': (i: ModuleWithIncludes) => toHumanString(i.included.sourceParticipation?.attributes.month),
        'trending-stars': (i: ModuleWithIncludes) =>
            ((i.included.sourceInfo?.attributes.stargazersWeeklyIncrease ?? 0) > 0 ? '+' : '') +
            `${toHumanString(i.included.sourceInfo?.attributes.stargazersWeeklyIncrease)}`,
        'most-closed-pulls': (i: ModuleWithIncludes) =>
            ((i.included.sourceInfo?.attributes.closedPullsWeeklyIncrease ?? 0) > 0 ? '+' : '') +
            `${toHumanString(i.included.sourceInfo?.attributes.closedPullsWeeklyIncrease)}`,
        'favorite': null,
    };
    const header = headers[type];
    const subtitle = subtitles[type];
    const URL = `${URLs[type]}&limit=${limit}`;

    const transformData = (d: any) => {
        const result: TopModulesStateType = [];

        const reference: RefType = {
            'module-versions': {},
            'module-downloads': {},
            'source-info': {},
            'source-participation': {}
        };

        for (const i of d.included) {
            if (i.type === 'module-versions' || i.type === 'source-info' || i.type === 'module-downloads' || i.type === 'source-participation') {
                reference[i.type as keyof RefType][i.id] = i;
            }
        }
        for (const i of d.data) {
            i.included = {};
            i.included.latestVersion = reference['module-versions'][i.relationships.latestVersion.data.id];
            if (!!i.relationships.moduleDownloads.data) {
                i.included.moduleDownloads = reference['module-downloads'][i.relationships.moduleDownloads.data.id];
            }
            if (!!i.relationships.sourceInfo.data) {
                i.included.sourceInfo = reference['source-info'][i.relationships.sourceInfo.data.id];
            }
            if (!!i.relationships.sourceParticipation.data) {
                i.included.sourceParticipation = reference['source-participation'][i.relationships.sourceParticipation.data.id];
            }
            if (
                (type == 'trending-stars' && !i.included.sourceInfo.attributes.stargazersWeeklyIncrease) ||
                (type == 'most-closed-pulls' && !i.included.sourceInfo.attributes.closedPullsWeeklyIncrease)
            ) {
                continue;
            }
            result.push(i);
        }

        return result;
    };

    React.useEffect(() => {
        API.get(URL)
            .then((response) => {
                setData(transformData(recursiveToCamel(response.data)));
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getDisplayValues = displayValues[type];

    return (
        <div className="insight" hidden={data.length == 0}>
            <Row className="rounded-pane pane-shadow">
                <Row>
                    <div className="widget-header-container">
                        <h5 className="px-2 fw-bold">
                            <PiParallelogram />
                            {header}
                        </h5>

                        {showSeeAll && type === 'favorite' && (moduleIds || []).length > limit && (
                            <Link to="/modules?favorite=1" className="nav-link p-2">
                                See all
                            </Link>
                        )}
                    </div>
                    {subtitle && (
                        <div>
                            <p className="px-2 text-secondary">{subtitle}</p>
                        </div>
                    )}
                </Row>
                <ListGroup as="div">
                    {data.map((item) => (
                        <ListGroup.Item
                            key={`module-${type}-${item.id}`}
                            as="a"
                            className="link d-flex justify-content-between align-items-start pointer"
                            action
                            href={`/modules/${item.attributes.fullName}/latest`}
                            title="Click to open the module"
                        >
                            <Col className="me-1 overflow-hidden">
                                <span className="fw-bold text-nowrap">
                                    {item.attributes.namespace} / {item.attributes.name} ({item.attributes.providerName})
                                </span>{' '}
                                <div className={`text-secondary text-truncate-${(short ? '1' : '2')}`}>
                                    {item.included.latestVersion.attributes.description}
                                </div>
                            </Col>
                            {getDisplayValues && (
                                <span className="text-end text-nowrap text-secondary" title={titles[type]}>
                                    {getDisplayValues(item)}
                                </span>
                            )}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Row>
        </div>
    );
};

export default TopModules;
