import React from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { TbLicense } from 'react-icons/tb';
import { GoEye, GoGitMerge, GoGitPullRequest, GoIssueClosed, GoIssueOpened, GoRepoForked, GoStar } from 'react-icons/go';
import { SlPeople } from 'react-icons/sl';

import type { SourceInfoResourceType } from '../resourceTypes';
import { toHumanString } from '../common';

export type SourceInfoProps = {
    readonly data: SourceInfoResourceType;
    readonly sourceURL: string;
};
export const SourceInfo: React.FC<SourceInfoProps> = ({ data, sourceURL }) => (
    <ListGroup as="div" className="list-group-border-0">
        {data && data?.attributes.isFork && (
            <ListGroup.Item as="div" className="link d-flex justify-content-between align-items-start">
                <div className="">
                    <GoRepoForked />
                    This repository is a <strong>fork</strong>
                </div>
            </ListGroup.Item>
        )}
        <ListGroup.Item as="div" className="d-flex justify-content-between align-items-start">
            <div>
                <TbLicense />
                License
            </div>
            <Badge className="text-end" bg="tint" pill>
                {data?.attributes.license ? data?.attributes.license : 'n/a'}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item as="a" className="link d-flex justify-content-between align-items-start pointer" href={`${sourceURL}/stargazers`} action>
            <div>
                <GoStar />
                Stargazers
            </div>
            <Badge className="text-end" bg="tint" pill hidden={!data || !data?.attributes.stargazersCount}>
                <span title="The number of stars.">{toHumanString(data?.attributes.stargazersCount)}</span>
                {data && !!data.attributes.stargazersWeeklyIncrease && (
                    <span
                        className={`ms-1 text-${data.attributes.stargazersWeeklyIncrease > 0 ? 'success' : 'danger'}`}
                        title={`${data.attributes.stargazersWeeklyIncrease > 0 ? 'Increase' : 'Decrease'} in the number of stars over the past week.`}
                    >
                        {data.attributes.stargazersWeeklyIncrease > 0 ? '+' : ''}
                        {toHumanString(data.attributes.stargazersWeeklyIncrease)}
                    </span>
                )}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item as="a" className="link d-flex justify-content-between align-items-start pointer" href={`${sourceURL}/watchers`} action>
            <div>
                <GoEye />
                Watchers
            </div>
            <Badge className="text-end" bg="tint" pill hidden={!data || !data?.attributes.watchersCount}>
                <span title="The number of watchers.">{toHumanString(data?.attributes.watchersCount)}</span>
                {data && !!data.attributes.watchersWeeklyIncrease && (
                    <span
                        className={`ms-1 text-${data.attributes.watchersWeeklyIncrease > 0 ? 'success' : 'danger'}`}
                        title={`${data.attributes.watchersWeeklyIncrease > 0 ? 'Increase' : 'Decrease'} in the number of watchers over the past week.`}
                    >
                        {data.attributes.watchersWeeklyIncrease > 0 ? '+' : ''}
                        {toHumanString(data.attributes.watchersWeeklyIncrease)}
                    </span>
                )}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item as="a" className="link d-flex justify-content-between align-items-start pointer" href={`${sourceURL}/graphs/contributors`} action>
            <div>
                <SlPeople />
                Contributors
            </div>
            <Badge className="text-end" bg="tint" pill hidden={!data || !data?.attributes.contributorsCount}>
                <span title="The number of contributors.">{toHumanString(data?.attributes.contributorsCount)}</span>
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item as="a" className="link d-flex justify-content-between align-items-start pointer" href={`${sourceURL}/forks`} action>
            <div>
                <GoRepoForked />
                Forks
            </div>
            <Badge className="text-end" bg="tint" pill hidden={!data || !data?.attributes.forksCount}>
                <span title="The number of forks.">{toHumanString(data?.attributes.forksCount)}</span>
                {data && !!data.attributes.forksWeeklyIncrease && (
                    <span
                        className={`ms-1 text-${data.attributes.forksWeeklyIncrease > 0 ? 'success' : 'danger'}`}
                        title={`${data.attributes.forksWeeklyIncrease > 0 ? 'Increase' : 'Decrease'} in the number of forks over the past week.`}
                    >
                        {data.attributes.forksWeeklyIncrease > 0 ? '+' : ''}
                        {toHumanString(data.attributes.forksWeeklyIncrease)}
                    </span>
                )}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item as="a" className="link d-flex justify-content-between align-items-start pointer" href={`${sourceURL}/issues`} action>
            <div>
                <GoIssueOpened />
                Open Issues
            </div>
            <Badge className="text-end" bg="tint" pill hidden={!data || !data.attributes.issuesCount}>
                <span title="The number of open issues.">{toHumanString(data?.attributes.openIssuesCount)}</span>
                {data && !!data.attributes.openIssuesWeeklyIncrease && (
                    <span
                        className={`ms-1 text-${data.attributes.openIssuesWeeklyIncrease < 0 ? 'success' : 'danger'}`}
                        title={`${data.attributes.openIssuesWeeklyIncrease > 0 ? 'Increase' : 'Decrease'} in the number of open issues over the past week.`}
                    >
                        {data.attributes.openIssuesWeeklyIncrease > 0 ? '+' : ''}
                        {toHumanString(data.attributes.openIssuesWeeklyIncrease)}
                    </span>
                )}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item
            as="a"
            className="link d-flex justify-content-between align-items-start pointer"
            action
            href={`${sourceURL}/issues/?q=is%3Aissue+is%3Aclosed`}
        >
            <div>
                <GoIssueClosed />
                Closed Issues
            </div>
            <Badge className="text-end" bg="tint" pill hidden={!data || !data.attributes.issuesCount}>
                <span title="The number of closed issues.">{toHumanString(data.attributes.closedIssuesCount)}</span>
                {data && !!data.attributes.closedIssuesWeeklyIncrease && (
                    <span
                        className={`ms-1 text-${data.attributes.closedIssuesWeeklyIncrease > 0 ? 'success' : 'danger'}`}
                        title={`${data.attributes.closedIssuesWeeklyIncrease > 0 ? 'Increase' : 'Decrease'} in the number of closed issues over the past week.`}
                    >
                        {data.attributes.closedIssuesWeeklyIncrease > 0 ? '+' : ''}
                        {toHumanString(data.attributes.closedIssuesWeeklyIncrease)}
                    </span>
                )}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item as="a" className="link d-flex justify-content-between align-items-start pointer" href={`${sourceURL}/pulls`} action>
            <div>
                <GoGitPullRequest />
                Open Pulls
            </div>
            <Badge className="text-end" bg="tint" pill hidden={!data || !data.attributes.pullsCount}>
                <span title="The number of open pull requests.">{toHumanString(data?.attributes.openPullsCount)}</span>
                {data && !!data.attributes.openPullsWeeklyIncrease && (
                    <span
                        className="ms-1 text-success"
                        title={`${data.attributes.openPullsWeeklyIncrease > 0 ? 'Increase' : 'Decrease'} in the number of open pull requests over the past week.`}
                    >
                        {data.attributes.openPullsWeeklyIncrease > 0 ? '+' : ''}
                        {toHumanString(data.attributes.openPullsWeeklyIncrease)}
                    </span>
                )}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item
            as="a"
            className="link d-flex justify-content-between align-items-start pointer"
            href={`${sourceURL}/pulls?q=is%3Apr+is%3Aclosed`}
            action
        >
            <div>
                <GoGitMerge />
                Closed Pulls
            </div>
            <Badge className="text-end" bg="tint" pill hidden={!data || !data.attributes.pullsCount}>
                <span title="The number of closed pull requests.">{toHumanString(data.attributes.closedPullsCount)}</span>
                {data && !!data.attributes.closedPullsWeeklyIncrease && (
                    <span
                        className="ms-1 text-success"
                        title={`${
                            data.attributes.closedPullsWeeklyIncrease > 0 ? 'Increase' : 'Decrease'
                        } in the number of closed pull requests over the past week.`}
                    >
                        {data.attributes.closedPullsWeeklyIncrease > 0 ? '+' : ''}
                        {toHumanString(data.attributes.closedPullsWeeklyIncrease)}
                    </span>
                )}
            </Badge>
        </ListGroup.Item>
    </ListGroup>
);

export default SourceInfo;
