import {
    ModuleListActionTypes,
    ModuleListFilterByProviderState,
    ModuleListFilterQueryState,
    isSetModuleListFilterByProviderAction,
    isSetModuleListFilterQueryAction
} from '../actionTypes';

export type ModuleListState = ModuleListFilterByProviderState & ModuleListFilterQueryState;

const initialModuleListState: ModuleListState = {
    provider: null,
    query: null
};

export function moduleListReducer(state = initialModuleListState, action: ModuleListActionTypes): ModuleListState {
    if (isSetModuleListFilterByProviderAction(action)) {
        return {
            ...state,
            provider: action.payload.provider
        };
    } else if (isSetModuleListFilterQueryAction(action)) {
        return {
            ...state,
            query: action.payload.query
        };
    } else {
        return state;
    }
}
