import React, {memo, useMemo} from 'react';

import {Markdown} from '../Markdown';

type MarkdownChangelogType = {
    readonly id: number;
    readonly changelog: string;
}
const MarkdownChangelog: React.FC<MarkdownChangelogType> = ({ id, changelog}) => (
    <Markdown key={id} override={['alerts', 'headings']}>
        {changelog}
    </Markdown>
);

export default memo(MarkdownChangelog);
