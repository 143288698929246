import { FavoritesStateType, FavoriteTypeEnum } from './types';

export const ADD_FAVORITE_LIST = "ADD_FAVORITE_LIST";
export const REMOVE_FAVORITE_LIST = "REMOVE_FAVORITE_LIST";
export const RENAME_FAVORITE_LIST = "RENAME_FAVORITE_LIST";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const ADD_FAVORITE = "ADD_FAVORITE";
export const INIT_FAVORITES = "INIT_FAVORITES";

type InitFavoriteActionType = {
    type: typeof INIT_FAVORITES;
    payload: {
        state: FavoritesStateType;
    };
}

type AddFavoriteListActionType = {
    type: typeof ADD_FAVORITE_LIST;
    payload: {
        name: string;
        type: FavoriteTypeEnum;
        items: number[];
    };
}

type RemoveFavoriteListActionType = {
    type: typeof REMOVE_FAVORITE_LIST;
    payload: {
        listId: number;
    };
}

type RemoveFavoriteActionType = {
    type: string;
    payload: {
        listId: number;
        itemId: number;
    };
}

type AddFavoriteActionType = {
    type: typeof ADD_FAVORITE;
    payload: {
        listId: number;
        itemId: number;
    };
}

type RenameFavoriteListActionType = {
    type: typeof RENAME_FAVORITE_LIST;
    payload: {
        listId: number;
        name: string;
    };
}

export const initFavoriteAction = (state: FavoritesStateType): InitFavoriteActionType => (
    { type: INIT_FAVORITES, payload: { state } }
);

export const addFavoriteListAction = (name: string, type: FavoriteTypeEnum, items: number[]) => (
    { type: ADD_FAVORITE_LIST, payload: { name, items, type } }
);

export const removeFavoriteListAction = (id: number) => (
    { type: REMOVE_FAVORITE_LIST, payload: { id } }
)

export const removeFavoriteAction = (listId: number, itemId: number) => (
    { type: REMOVE_FAVORITE, payload: { listId, itemId } }
)

export const addFavoriteAction = (listId: number, itemId: number) => (
    { type: ADD_FAVORITE, payload: { listId, itemId } }
)

export const renameFavoriteAction = (listId: number, name: string) => (
    { type: RENAME_FAVORITE_LIST, payload: { listId, name } }
)

export type ActionsType = InitFavoriteActionType
| AddFavoriteListActionType
| RemoveFavoriteListActionType
| RemoveFavoriteActionType
| AddFavoriteActionType
| RenameFavoriteListActionType;

export const isInitFavoriteAction = (action: ActionsType): action is InitFavoriteActionType => {
    return action.type === INIT_FAVORITES;
}

export const isAddFavoriteAction = (action: ActionsType): action is AddFavoriteActionType => {
    return action.type === ADD_FAVORITE;
}

export const isRemoveFavoriteAction = (action: ActionsType): action is RemoveFavoriteActionType => {
    return action.type === REMOVE_FAVORITE;
}

export const isAddFavoriteListAction = (action: ActionsType): action is AddFavoriteListActionType => {
    return action.type === ADD_FAVORITE_LIST;
}

export const isRemoveFavoriteListAction = (action: ActionsType): action is RemoveFavoriteListActionType => {
    return action.type === REMOVE_FAVORITE_LIST;
}

export const isRenameFavoriteListAction = (action: ActionsType): action is RenameFavoriteListActionType => {
    return action.type === RENAME_FAVORITE_LIST;
}
