import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ProviderList from './list';
import ProviderListFilter from './filter';
import { providerDescription } from '../constants';
import PageHeader from '../pageHeader';

export type {
    CategoryResourceType,
    TierResourceType,
    ProviderCardPropsType,
    ProviderState,
    DocCategory,
    DocSubcategory,
    ProviderDocumentationStateType,
    ProviderVersionItemType,
    ProviderDocumentationResourceType,
    SourceChangelogResourceType,
    OverviewPropsType,
    ProviderTabTypes
} from './types';

export const ProvidersListPage = () => (
    <Row id="content">
        <Col md={4} lg={3} xxl={2} className="sidebar">
            <ProviderListFilter />
        </Col>
        <Col md={8} lg={9} xxl={10}>
            <PageHeader
                title="Providers"
            >
                {providerDescription}
            </PageHeader>
            <ProviderList />
        </Col>
    </Row>
);


export default ProvidersListPage;
