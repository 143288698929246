import React from 'react';

export type GraphProps = {
    readonly rawData: number[];
};
export const Graph: React.FC<GraphProps & React.HTMLProps<SVGElement>> = ({ rawData, children, ref, ...props }) => {
    const yScale = 10;
    const array = React.useMemo((): number[] => {
        const res: number[] = [];
        let maximum = 0;
        for (let i = 0; i < 52; ++i) {
            if (rawData[i] !== undefined) {
                if (maximum < rawData[i]) {
                    maximum = rawData[i];
                }
                res.push(rawData[i]);
            } else {
                res.push(0);
            }
        }
        for (let i = 0; i < 52; ++i) {
            res[i] = maximum == 0 ? yScale - 1 : yScale - 1 - Math.round((res[i] * (yScale - 1)) / maximum);
        }

        return res;
    }, [rawData]);

    return (
        <svg
            className="graph"
            stroke="currentColor"
            fill="currentColor"
            fillOpacity="1"
            strokeWidth="0"
            viewBox="0 0 52 10"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect className="graph-substrate" x="0" y="0" width="52" height="10" rx="0.1em" ry="0.1em" />
            {array.map((y, key) => (
                // eslint-disable-next-line react/no-array-index-key
                <g key={key}>
                    <rect className="graph-col" x={key} y={y} rx=".6%" width="0.6px" height={yScale - y + 1} />
                    <rect className="graph-col-hover" x={key} y={0} width=".6px" height={10} />
                    <text x={key > 25 ? key - String(rawData[key]).length * 4.7 - 2 : key + 3} y="7" visibility="hidden">
                        {rawData[key]}
                    </text>
                </g>
            ))}
        </svg>
    );
};

export default Graph;
