import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import API from '../../api';
import { ProviderCardPropsType, ProviderListState } from './types';
import { explodeList } from '../common';
import { ProviderCard } from './providerCard';
import { transformProvidersData } from './utils';
import { FavoriteContext } from '../Favorite/favoriteContext';
import { FAVORITE_PROVIDERS_LIST_ID } from '../Favorite/useFavorite';

const emptyFavoriteList: number[] = [];

export const ProviderList = () => {
    const [filterParams] = useSearchParams();
    const [state, setState] = useState<ProviderListState>({
        items: [],
        page: 0,
        hasMore: true
    });
    const { getFavoriteItems } = useContext(FavoriteContext);
    const favoriteItems = getFavoriteItems(FAVORITE_PROVIDERS_LIST_ID);

    const category = explodeList(filterParams.get('category'));
    const tier = explodeList(filterParams.get('tier'));
    const badges = explodeList(filterParams.get('badges'));
    const query = filterParams.get('query');
    const showFavoriteOnly = filterParams.get('favorite') === '1';
    const favorite = showFavoriteOnly ? favoriteItems : emptyFavoriteList;

    useEffect(() => {
        fetchMoreData(true);
    }, [filterParams, favorite]);

    const fetchMoreData = (reset = false) => {
        const page = reset ? 0 : state.page;
        let apiURL = '/providers?include=' + encodeURIComponent('categories,latest-version,source-participation');
        apiURL += '&page=' + (page + 1);

        if (showFavoriteOnly) {
            apiURL += `&filter[id]=` + encodeURIComponent(favoriteItems.join(','));
        }
        if (category.length > 0) {
            apiURL += `&filter[category]=` + encodeURIComponent(category.join(','));
        }
        if (tier.length > 0) {
            apiURL += `&filter[tier]=` + encodeURIComponent(tier.join(','));
        }
        if (badges.length > 0) {
            apiURL += `&filter[badges]=` + encodeURIComponent(badges.join(','));
        }
        if (query) {
            apiURL += `&filter[query]=` + encodeURIComponent(query);
        } else {
            // Always sort by the downloads except when the filter query is present, otherwise sort by the search relevance.
            apiURL += '&sort=' + encodeURIComponent('-downloads');
        }

        API.get(apiURL)
            .then((response) => {
                const r = response.data;

                setState({
                    items: reset ? transformProvidersData(r) : state.items?.concat(transformProvidersData(r)),
                    page: r['meta']['pagination']['current-page'],
                    hasMore: r['meta']['pagination']['next-page'] != null
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Row>
            <InfiniteScroll
                dataLength={state.items ? state.items.length : 0}
                next={fetchMoreData}
                hasMore={state.hasMore}
                loader={<div className="text-center">Loading...</div>}
            >
                {state.items.map(function (props: ProviderCardPropsType) {
                    return <ProviderCard key={`provider-${props.provider.id}`} {...props} />;
                })}
            </InfiniteScroll>
            <Row hidden={state.items.length !== 0 || state.hasMore} className="px-0 mx-0">
                <Col className="rounded-pane-bottom">No records found for your search</Col>
            </Row>
        </Row>
    );
};

export default ProviderList;
