import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { CiFilter } from 'react-icons/ci';

type DocumentSearchPropsType = {
    readonly value?: string;
    readonly onSearch?: (value: string) => void;
};

const contextData = { className: 'react-icons' };

export const DocumentSearch: FC<DocumentSearchPropsType> = ({ value, onSearch }) => {
    const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> | undefined = (event) => {
        if (onSearch) {
            onSearch(event.target.value);
        }
    };

    return (
        <div className="document-search">
            <IconContext.Provider value={contextData}>
                <CiFilter className="global-search-icon" />
                <Form.Control
                    type="text"
                    size="sm"
                    className="search"
                    placeholder="Filter"
                    value={value}
                    onChange={onChangeHandler}
                />
            </IconContext.Provider>
        </div>
    );
};
