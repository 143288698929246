import { useSelector } from 'react-redux';
import { getSiteTheme } from '../redux/selectors';

export const recursiveToCamel = (item: any): any => {
    if (Array.isArray(item)) {
        return item.map((el: any) => recursiveToCamel(el));
    } else if (typeof item === 'function' || item !== Object(item)) {
        return item;
    }
    return Object.fromEntries(
        Object.entries(item as Record<string, any>).map(([key, value]: [string, any]) => [
            key.replace(/([-_][a-z])/gi, (c) => c.toUpperCase().replace(/[-_]/g, '')),
            recursiveToCamel(value)
        ])
    );
};

export const explodeList = (str: string | null) =>
    str === null
        ? []
        : str
            .split(',')
            .map((s) => s.trim())
            .filter((e) => e !== '');

export const isDarkTheme = () => {
    const theme = useSelector(getSiteTheme) ?? 'auto';
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return true;
    } else {
        return theme === 'dark';
    }
};

const PREFIXES: any = {
    '24': 'Y',
    '21': 'Z',
    '18': 'E',
    '15': 'P',
    '12': 'T',
    '9': 'B',
    '6': 'M',
    '3': 'k',
    '0': '',
    '-3': 'm',
    '-6': 'µ',
    '-9': 'n',
    '-12': 'p',
    '-15': 'f',
    '-18': 'a',
    '-21': 'z',
    '-24': 'y'
};

const getExponent = (n: any) => {
    if (n === 0) {
        return 0;
    }
    return Math.floor(Math.log10(Math.abs(n)));
};

const precise = (n: any) => {
    return Number.parseFloat(n.toPrecision(3));
};

export const toHumanString = (sn: any): string => {
    const n = precise(Number.parseFloat(sn));
    const e = Math.max(Math.min(3 * Math.floor(getExponent(n) / 3), 24), -24);
    return precise(n / Math.pow(10, e)).toString() + PREFIXES[e];
};
