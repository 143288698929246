import React, { FC } from 'react';
import { useFavorite } from './useFavorite';
import { FavoriteContext } from './favoriteContext';

type FavoriteContainerPropsType = {
    readonly children: React.ReactNode;
}

export const FavoriteContainer: FC<FavoriteContainerPropsType> = ({ children }) => {
    const favorite = useFavorite();

    return (
        <FavoriteContext.Provider value={favorite}>
            {children}
        </FavoriteContext.Provider>
    );
}
