type ClassNameTupleType =
    | [className: string]
    | [className: string, isEnabled: boolean | undefined]
    | [classNameA: string, classNameB: string, toggle: boolean | undefined];

export type ClassNameType = ClassNameTupleType | string | undefined;

type ClassNameExtractorType = (item: ClassNameType) => string | null;

const CLASS_NAME = 0;
const ALT_CLASS_NAME = 1;
const PRESENCE = 1;
const SWITCH = 2;

const getClassName: ClassNameExtractorType = (item = '') => {
    if (typeof item === 'string') {
        return item;
    }

    if (item.length === 3) {
        const option = item[SWITCH] ? CLASS_NAME : ALT_CLASS_NAME;

        return item[option];
    }

    if (item.length === 1 || item[PRESENCE]) {
        return item[CLASS_NAME];
    }

    return null;
};

export const classNames = (...classes: ClassNameType[]) => classes.reduce<string[]>((result, item) => {
    const className = getClassName(item);

    if (className) {
        result.push(className);
    }

    return result;
}, []).join(' ');
