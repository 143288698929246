import React, { useState } from 'react';
import { ListGroup, Row, Table } from 'react-bootstrap';
import moment from 'moment/moment';

import { BasicVersionData } from '../resourceTypes';
import { toHumanString } from '../common';

export const VersionList = ({
    versions,
    onClick,
    limit,
    showAll
}: {
    readonly versions: BasicVersionData[];
    readonly onClick?: ((ver: string) => void) | undefined;
    readonly limit?: number;
    readonly showAll?: boolean;
}) => {
    limit = limit ?? 27;
    const [all, setAll] = useState(showAll ?? false);
    const list = all ? versions : versions.slice(0, limit);

    return (
        <Row hidden={versions.length === 0}>
            <Table hover responsive className="d-block w-100 d-sm-table version-table">
                <thead>
                    <tr>
                        <th>Version</th>
                        <th className="text-end">Downloads</th>
                        <th className="text-end">Published</th>
                    </tr>
                </thead>
                <tbody>
                    {list.map(function (props, index) {
                        return (
                            <tr
                                key={`tr-version-${props.id}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (onClick) {
                                        onClick(props.attributes.version ? props.attributes.version : 'latest');
                                    }
                                }}
                                className="pointer"
                                title={`Click to select version ${props.attributes.version}`}
                            >
                                <td>
                                    {props.attributes.version}
                                    {!index ? ' (latest)' : ''}
                                </td>
                                <td align="right">{toHumanString(props.attributes.downloads)}</td>
                                <td align="right">{moment(props.attributes.publishedAt, 'YYYY-MM-DDThh:mm:ssZ').fromNow()}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {!all && versions.length > limit && (
                <ListGroup>
                    <ListGroup.Item className="link text-center pointer" action onClick={() => setAll(true)}>
                        Display all versions
                    </ListGroup.Item>
                </ListGroup>
            )}
        </Row>
    );
};

export default VersionList;
