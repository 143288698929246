import React, { useEffect, useState } from 'react';
import {RiArrowDownSLine, RiArrowRightSLine} from 'react-icons/ri';

import {wordWrapByUnderscores, adjustResourceName} from './utils';
import {RawLink} from '../RawLink';
import {DocCategory, DocSubcategory, ProviderDocumentationResourceType} from './types';
import { Highlight } from './highlight';

type SubCategoryNodeProps = {
    readonly cat: DocCategory;
    readonly sub: DocSubcategory;
    readonly providerName: string;
    readonly selected: ProviderDocumentationResourceType;
    readonly selectedCat: string | null;
    readonly selectedSub: string | null;
    readonly highlightedText: string;
    readonly expanded: boolean;
    readonly selectDoc: (doc: ProviderDocumentationResourceType) => void;
    readonly getDocUrl: (doc: ProviderDocumentationResourceType) => string;
};

export const SubCategoryNode: React.FC<SubCategoryNodeProps> = (
    {
        cat,
        sub,
        providerName,
        selectedCat,
        selectedSub,
        expanded,
        highlightedText,
        selected,
        selectDoc,
        getDocUrl,
    }
) => {
    const [subOpen, setSubOpen] = useState(
        (sub.title === selectedCat && cat.title === selectedSub) || expanded
    );

    useEffect(() => {
        setSubOpen((sub.title === selectedCat && cat.title === selectedSub) || expanded);
    }, [expanded, sub.title, selectedCat, cat.title, selectedSub]);

    return (
        <li key={`li-cat-${sub.title}//${cat.title}`} className="ps-2">
            <span
                className="d-flex py-2 top-level"
                onClick={() => setSubOpen(!subOpen)}
            >
                <span className="d-block position-relative" style={{marginLeft: '-1em'}}>
                    {subOpen ? <RiArrowDownSLine/> : <RiArrowRightSLine/>}
                </span>
                <Highlight text={cat.title} searchString={highlightedText} capitalize/>
            </span>
            <ul key={`ul-cat-${sub.title}//${cat.title}`} className="nav nav-link p-0" hidden={!subOpen}>
                {cat.items.map((doc) => (
                    <li key={`doc-${doc.id}`} className="ps-2 border-start">
                        <RawLink
                            href={getDocUrl(doc)}
                            className={`d-flex py-2 ${!!selected && selected.id === doc.id ? 'active' : ''}`}
                            onClick={() => selectDoc(doc)}
                        >
                            <Highlight
                                text={wordWrapByUnderscores(
                                    doc.attributes.category !== 'guides'
                                        ? adjustResourceName(doc.attributes.title, providerName)
                                        : doc.attributes.title,
                                )}
                                searchString={wordWrapByUnderscores(highlightedText)}
                            />
                        </RawLink>
                    </li>
                ))}
            </ul>
        </li>
    );
};
