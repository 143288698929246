import { useEffect, useReducer } from 'react';
import { FavoritesStateType, FavoriteTypeEnum } from './types';
import { favoritesReducer } from './reducer';
import { addFavoriteAction, initFavoriteAction, removeFavoriteAction } from './actions';

export const FAVORITE_PROVIDERS_LIST_ID = 1;
export const FAVORITE_MODULES_LIST_ID = 2;

const initFavoriteState: FavoritesStateType = {
    autoIncrement: 2,
    version: 1,
    favorites: [
        {
            id: FAVORITE_PROVIDERS_LIST_ID,
            type: FavoriteTypeEnum.provider,
            name: 'My favorite providers',
            items: [],
        }, {
            id: FAVORITE_MODULES_LIST_ID,
            type: FavoriteTypeEnum.module,
            name: 'My favorite modules',
            items: [],
        },
    ],
}

const rootFavoriteState = 'favoritesStateRoot';

export const useFavorite = () => {
    const [favoriteState, dispatch] = useReducer(favoritesReducer, initFavoriteState);

    useEffect(() => {
        const localState = JSON.parse(localStorage.getItem(rootFavoriteState) || JSON.stringify(initFavoriteState));

        if (localState) {
            dispatch(initFavoriteAction(localState as FavoritesStateType));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(rootFavoriteState, JSON.stringify(favoriteState));
    }, [favoriteState]);

    const removeFavoriteItem = (listId: number, itemId: number) => {
        dispatch(removeFavoriteAction(listId, itemId));
    }

    const addFavoriteItem = (listId: number, itemId: number) => {
        dispatch(addFavoriteAction(listId, itemId));
    }

    const getFavoriteList = (listId: number) => (
        favoriteState.favorites.find((favorite) => favorite.id === listId)
    );

    const getFavoriteItems = (listId: number): number[] => {
        const favoriteList = getFavoriteList(listId);

        return favoriteList ? favoriteList.items : [];
    };

    return {
        getFavoriteItems,
        removeFavoriteItem,
        addFavoriteItem,
    };
}
