import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Outlet, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function ScrollToTop() {
    const { pathname } = useLocation();
    const { height, width } = useWindowDimensions();

    React.useLayoutEffect(() => {
    // Scroll to the documentation content when clicking on the doc category sidebar.
        if (pathname.match(/\/providers\/.+\/docs\/.+/)) {
            const element: HTMLElement | null = document.querySelector(`[id="doc-content"]`);
            if (element) {
                if (width < 992) {
                    // md screen size. In this case the doc content is far below.
                    element.scrollIntoView();
                } else {
                    element.parentElement?.parentElement?.parentElement?.scrollIntoView();
                }

                return;
            }
        }

        window.scrollTo({ top: 0 });
    }, [pathname]);

    return null;
}

root.render(
    <Provider store={configureStore()}>
        <BrowserRouter>
            <App />
            <Outlet />
            <ScrollToTop />
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
