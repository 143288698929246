import React from 'react';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';
import { classNames } from '../../utils/classNames';

type FavoriteCountPropsType = {
    readonly count: number;
    readonly showFavoriteOnly?: boolean;
    readonly title?: string;
    readonly onClick: () => void;
}

const defaultTitle = 'Show favorite list';

export const FavoriteCount = ({ count, showFavoriteOnly = false, title = defaultTitle, onClick }: FavoriteCountPropsType) => (
    <div>
        <div className={classNames('favoriteCount', ['selected', showFavoriteOnly])} onClick={onClick} title={title}>
            <div>
                { showFavoriteOnly ? <MdFavorite className="react-icons" /> : <MdFavoriteBorder className="react-icons" /> }
            </div>
            Favorites
            <div className="count">
                {count}
            </div>
        </div>
    </div>
)
