import React, { FC } from 'react';

// @ts-ignore
import Icons from "./sprites.svg";

type IconPropsType = {
    readonly id: string,
    readonly className?: string,
}

export const Icon: FC<IconPropsType> = ({ id, className }) => (
    <svg className={className}>
        <use href={Icons + '#' + id} />
    </svg>
)

