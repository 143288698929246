import React from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import { GoDownload } from 'react-icons/go';

import { toHumanString } from '../common';

export type DownloadsSummaryProps = {
    readonly week?: number;
    readonly month?: number;
    readonly year?: number;
    readonly total?: number;
};

export const DownloadsSummary: React.FC<DownloadsSummaryProps> = ({ week, month, year, total }) => (
    <ListGroup as="ol" className="list-group-border-0">
        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div>
                <GoDownload />
                Downloads this week
            </div>
            <Badge className="text-end" bg="tint" pill>
                {toHumanString(week ?? 0)}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div>
                <GoDownload />
                Downloads this month
            </div>
            <Badge className="text-end" bg="tint" pill>
                {toHumanString(month ?? 0)}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div>
                <GoDownload />
                Downloads this year
            </div>
            <Badge className="text-end" bg="tint" pill>
                {toHumanString(year ?? 0)}
            </Badge>
        </ListGroup.Item>

        <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div>
                <GoDownload />
                Downloads total
            </div>
            <Badge className="text-end" bg="tint" pill>
                {toHumanString(total ?? 0)}
            </Badge>
        </ListGroup.Item>
    </ListGroup>
);

export default DownloadsSummary;
