import React from 'react';
import { Card, Image, Nav, Tab } from 'react-bootstrap';

import { Markdown } from '../Markdown';
import { Icon } from '../Icon/Icon';

export type ConfigurationInstructionsProps = {
    readonly tofuInfo?: string | React.ReactNode;
    readonly terraformInfo: string | React.ReactNode;
} & React.PropsWithChildren;

export const ConfigurationInstructions: React.FC<ConfigurationInstructionsProps> = ({
    tofuInfo,
    terraformInfo,
    children
}) => {
    const code = '```';

    const copyToBuffer = (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(children?.toString() ?? '');
    };

    return (
        <Tab.Container defaultActiveKey={tofuInfo ? "opentofu" : "terraform"}>
            <Card className="px-0 border-0 mx-n4 bg-transparent">
                <Card.Header className="bg-transparent">
                    <Nav variant="tabs mx-3">
                        {tofuInfo && (
                            <Nav.Item>
                                <Nav.Link href="#" eventKey="opentofu">
                                    <span>
                                        <Icon id="opentofu" className="me-1 mb-1 platformIcon" />
                                        OpenTofu
                                    </span>
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        <Nav.Item>
                            <Nav.Link href="#" eventKey="terraform">
                                <span>
                                    <Icon id="terraform" className="me-1 mb-1 platformIcon" />
                                    Terraform
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body onClick={copyToBuffer} className="pointer mx-3 pb-0">
                    <Tab.Content>
                        {tofuInfo && (
                            <Tab.Pane eventKey="opentofu">
                                <Card.Text>{tofuInfo}</Card.Text>
                                <Markdown>{`${code}hcl\n${children}\n${code}`}</Markdown>
                            </Tab.Pane>
                        )}
                        <Tab.Pane eventKey="terraform">
                            <Card.Text>{terraformInfo}</Card.Text>
                            <Markdown>{`${code}hcl\n${children}\n${code}`}</Markdown>
                        </Tab.Pane>
                    </Tab.Content>
                </Card.Body>
            </Card>
        </Tab.Container>
    );
};

export default ConfigurationInstructions;
