import { isOfTypeModuleTab } from './index';
import { Alert, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { BsFileText } from 'react-icons/bs';
import Markdown from '../Markdown';
import { MdInput, MdOutput } from 'react-icons/md';
import { Inputs } from './input';
import { Outputs } from './output';
import { FaCubes } from 'react-icons/fa';
import { Dependencies } from './dependencies';
import { GrResources } from 'react-icons/gr';
import { Resources } from './resources';
import { GoLog, GoRepoForked, GoVersions } from 'react-icons/go';
import VersionList from '../VersionList';
import { ForksList } from './forkList';
import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ModuleVersionData, ModuleVersionResponseDataType, SourceChangelogResourceType } from './types';

type ModuleTabsPropsType = {
    readonly selectedSubmoduleId: number | null;
    readonly exampleIds: number[];
    readonly readme: string | null;
    readonly subModuleInputIds: number[],
    readonly subModuleOutputIds: number[];
    readonly subModuleProviderDependencyIds: number[],
    readonly subModuleDependencyIds: number[],
    readonly subModuleResourceIds: number[],
    readonly moduleVersionData: ModuleVersionResponseDataType | null;
    readonly changelog: SourceChangelogResourceType | null;
    readonly allVersions: ModuleVersionData[];
    readonly moduleId: number;
    readonly moduleFullName: string;
    readonly onSetVersion?: ((ver: string) => void) | undefined;
    readonly forksCount?: number;
};

export const ModuleTabs: FC<ModuleTabsPropsType> = ({
    selectedSubmoduleId,
    exampleIds,
    readme,
    subModuleInputIds,
    subModuleOutputIds,
    subModuleProviderDependencyIds,
    subModuleDependencyIds,
    subModuleResourceIds,
    moduleVersionData,
    changelog,
    allVersions,
    moduleId,
    moduleFullName,
    onSetVersion,
    forksCount = 0,
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const qTab = searchParams.get('tab');

    const isExamples = selectedSubmoduleId
        ? exampleIds.includes(selectedSubmoduleId)
        : false;

    const hasReadme = readme !== '';

    let defaultActiveKey = !!qTab && isOfTypeModuleTab(qTab) ? qTab : 'readme';

    if (!hasReadme && !qTab && !selectedSubmoduleId) {
        if (subModuleInputIds.length) {
            defaultActiveKey = 'inputs';
        } else if (subModuleOutputIds.length) {
            defaultActiveKey = 'outputs';
        }
    }

    const linksOverride = (p: any) => {
        const { node, children, href, ...rest } = p;
        let newHref = p.href;
        const expressions = [
            [/^(?:https:\/\/registry.terraform.io\/providers)\/([\w-]+)\/([\w-]+)\/([\w.]+)\/docs\/(.+)$/, '/providers/$1/$2/$3/docs/$4']
        ];

        if (typeof href === 'string' && href.match(/^https?:\/\//)) {
            for (const d of expressions) {
                if (href.match(d[0])) {
                    newHref = newHref.replace(d[0], d[1]);

                    break;
                }
            }
        }
        return (
            <a href={newHref} {...rest}>
                {children}
            </a>
        );
    };

    return (
        <Row>
            <Col lg={12} className="py-4">
                <Tabs
                    id="module-version-tab"
                    defaultActiveKey={defaultActiveKey}
                    activeKey={defaultActiveKey}
                    onSelect={(k) => {
                        if (!!k && isOfTypeModuleTab(k)) {
                            setSearchParams((params) => {
                                if (k === 'readme') {
                                    params.delete('tab');
                                } else {
                                    params.set('tab', k);
                                }

                                return params;
                            });
                        }
                    }}
                    className="rounded-pane"
                    transition={false}
                >
                    <Tab
                        eventKey="readme"
                        title={
                            <span>
                                <BsFileText />
                                Readme
                            </span>
                        }
                    >
                        {hasReadme && (
                            <Markdown override={['alerts', 'headings', { a: linksOverride }]}>{readme}</Markdown>
                        )}
                        {!hasReadme && !!selectedSubmoduleId && (
                            <Alert variant="warning">
                                This submodule is used internally by
                                the <strong>{moduleFullName.replaceAll('/', ' / ')}</strong> module.
                                Using this submodule on its own is not recommended. Submodules without
                                a <code>README</code> or <code>README.md</code> are
                                considered to be internal-only by the Terraform Registry. If this submodule should not
                                be considered
                                internal, add a readme which
                                describes what this submodule is for and how it should be used.
                            </Alert>
                        )}
                    </Tab>
                    <Tab
                        eventKey="inputs"
                        title={
                            <span>
                                <MdInput />
                                Inputs ({subModuleInputIds.length})
                            </span>
                        }
                    >
                        <Inputs
                            moduleVersionData={moduleVersionData}
                            subModuleInputIds={subModuleInputIds}
                        />
                    </Tab>
                    <Tab
                        eventKey="outputs"
                        title={
                            <span>
                                <MdOutput />
                                Outputs ({subModuleOutputIds.length})
                            </span>
                        }
                    >
                        <Outputs
                            moduleVersionData={moduleVersionData}
                            subModuleOutputIds={subModuleOutputIds} />
                    </Tab>
                    {!isExamples && (
                        <Tab
                            eventKey="dependencies"
                            title={
                                <span>
                                    <FaCubes />
                                    Dependencies ({subModuleProviderDependencyIds.length + subModuleDependencyIds.length})
                                </span>
                            }
                        >
                            <Dependencies
                                moduleVersionData={moduleVersionData}
                                subModuleProviderDependencyIds={subModuleProviderDependencyIds}
                                subModuleDependencyIds={subModuleDependencyIds}
                            />
                        </Tab>
                    )}
                    {!isExamples && (
                        <Tab
                            eventKey="resources"
                            title={
                                <span>
                                    <GrResources />
                                    Resources ({subModuleResourceIds.length})
                                </span>
                            }
                        >
                            <Resources
                                moduleVersionData={moduleVersionData}
                                subModuleResourceIds={subModuleResourceIds}
                            />
                        </Tab>
                    )}
                    {changelog !== null && (
                        <Tab
                            eventKey="changelog"
                            title={
                                <span>
                                    <GoLog />
                                    Changelog
                                </span>
                            }
                        >
                            <Markdown override={['alerts', 'headings']}>{changelog.attributes.changelog}</Markdown>
                        </Tab>
                    )}
                    <Tab
                        eventKey="versions"
                        title={
                            <span>
                                <GoVersions />
                                Versions ({allVersions.length})
                            </span>
                        }
                    >
                        <VersionList versions={allVersions} onClick={onSetVersion} />
                    </Tab>
                    {forksCount > 0 && (
                        <Tab
                            key="forks-tab"
                            eventKey="forks"
                            title={
                                <span>
                                    <GoRepoForked />
                                    Forks ({forksCount})
                                </span>
                            }
                        >
                            {defaultActiveKey === 'forks' && (
                                <ForksList moduleId={moduleId} />
                            )}
                        </Tab>
                    )}
                </Tabs>
            </Col>
        </Row>
    );
};
