import React from 'react';
import { ModuleCardPropsType } from './types';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { Col, Image, Row } from 'react-bootstrap';
import { BsClock } from 'react-icons/bs';
import moment from 'moment/moment';
import { GoDownload } from 'react-icons/go';
import Graph from '../Graph';

import { toHumanString } from '../common';
import { Favorite } from '../Favorite/favorite';
import { FAVORITE_MODULES_LIST_ID } from '../Favorite/useFavorite';
import { Icon } from '../Icon/Icon';

export const ModuleCard: React.FC<ModuleCardPropsType> = ({ module, sourceParticipation }) => (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <IconContext.Provider value={{ className: 'react-icons' }}>
        <Link to={`/modules/${module.attributes.fullName}/latest`} key={`modules-${module.id}`} className="module-card rounded-pane-middle">
            <Row className="mb-2">
                <Col className="entity-card-header h4">
                    {module.attributes.namespace} / {module.attributes.name}
                </Col>
                <Col className="col-auto">
                    <Favorite itemId={module.id} listId={FAVORITE_MODULES_LIST_ID}/>
                </Col>
            </Row>
            <Row>
                <Col className="text-muted">{module.attributes.description}</Col>
            </Row>
            <Row className="mt-3 text-secondary">
                <Col title="Last publish">
                    <BsClock />
                    {moment(module.attributes.publishedAt, 'YYYY-MM-DDThh:mm:ssZ').fromNow()}
                </Col>
                <Col title="Downloads total">
                    <GoDownload />
                    {toHumanString(module.attributes.downloads)}
                </Col>
                <Col title="Weekly commits for the past 52 weeks">
                    <div className="mt-n1">
                        {!!sourceParticipation && (
                            <Graph height="1.3em" rawData={sourceParticipation.attributes.data.all} />
                        )}
                    </div>
                </Col>
                <Col>
                    <span className="fw-bold">{module.attributes.providerName}</span>
                    {' '}
                    <span className="text-muted">provider</span>
                </Col>
                <Col
                    title={`This module is available in ${module.attributes.isInTofuRegistry ? 'OpenTofu and Terraform registries.' : 'Terraform registry.'}`}
                >
                    <span className="nowrap">
                        <span className="hide-less-lg">Available in: </span>
                        {module.attributes.isInTofuRegistry && (
                            <>
                                <Icon id="opentofu" className="me-1 mb-1 platformIcon" />
                                {` / `}
                            </>
                        )}
                        <Icon id="terraform" className="me-1 mb-1 platformIcon" />
                    </span>
                </Col>
            </Row>
        </Link>
    </IconContext.Provider>
);
