import React, { FC, useEffect, useState } from 'react';
import API from '../../api';

import { transformModulesData } from './utils';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ModuleCardPropsType, ModuleExtendedListStateType } from './types';
import { ModuleCard } from './moduleCard';

type ForksListPropsType = {
    readonly moduleId: number;
};

export const ForksList: FC<ForksListPropsType> = ({ moduleId }) => {
    const [state, setState] = useState<ModuleExtendedListStateType>({
        items: [],
        page: 0,
        hasMore: true
    });

    const fetchForksData = (reset = false) => {
        const page = reset ? 0 : state.page;
        let apiURL = '/modules?include=' + encodeURIComponent('latest-version,source-participation');
        apiURL += '&page=' + (page + 1);

        apiURL += `&filter[potential-fork-of]=` + encodeURIComponent(moduleId);
        apiURL += '&sort=' + encodeURIComponent('-downloads');

        API.get(apiURL)
            .then((response) => {
                const data = response.data;

                setState({
                    items: reset ? transformModulesData(data) : state.items.concat(transformModulesData(data)),
                    page: data['meta']['pagination']['current-page'],
                    hasMore: data['meta']['pagination']['next-page'] != null
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchForksData(true);
    }, [moduleId]);

    return (
        <Row className="forks-list">
            <InfiniteScroll
                dataLength={state.items ? state.items.length : 0}
                next={fetchForksData}
                hasMore={state.hasMore}
                loader={<div className="text-center">Loading...</div>}
                scrollThreshold={0.6}
            >
                {state.items.map((props: ModuleCardPropsType) => (
                    <ModuleCard key={`module-${props.module.id}`} {...props} />
                ))}
            </InfiniteScroll>
            <Row hidden={state.items.length !== 0 || state.hasMore} className="px-0 mx-0">
                <Col className="rounded-pane-bottom">No forks found.</Col>
            </Row>
        </Row>
    )
}
