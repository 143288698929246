import { Col, Row } from 'react-bootstrap';
import Markdown from '../Markdown';
import React, { FC } from 'react';
import { ModuleVersionResponseDataType } from './types';

type InputPropsType = {
    readonly subModuleInputIds: number[],
    readonly moduleVersionData: ModuleVersionResponseDataType | null;
}
export const Inputs: FC<InputPropsType> = ({ subModuleInputIds, moduleVersionData }) => {
    const required_inputs = [];
    const optional_inputs = [];
    const relationships = moduleVersionData ? moduleVersionData.included : null;

    if (subModuleInputIds.length === 0 || !relationships) {
        return <p>This module has no input variables.</p>;
    }

    for (const key in subModuleInputIds) {
        const inputId = subModuleInputIds[key];
        if (relationships.inputs[inputId]) {
            if (relationships.inputs[inputId].attributes.required) {
                required_inputs.push(relationships.inputs[inputId]);
            } else {
                optional_inputs.push(relationships.inputs[inputId]);
            }
        }
    }

    return (
        <Col>
            <Row hidden={required_inputs.length === 0} className="pb-4">
                <Col lg={12} className="h3">
                    Required inputs
                </Col>
                <Col lg={12}>
                    <i>
                        These variables must be set in the <code>module</code> block when using this module.
                    </i>
                </Col>

                {required_inputs.map((input) => (
                    <Row key={`input-${input.id}`} className="mt-3">
                        <Col lg={12}>
                            <span className="fw-bold">{input.attributes.name}</span>{' '}
                            <code>{input.attributes.type ? ` (${input.attributes.type})` : ''}</code>
                        </Col>
                        <Col lg={12}>
                            <Markdown>{input.attributes.description}</Markdown>
                        </Col>
                    </Row>
                ))}
            </Row>
            <Row hidden={optional_inputs.length === 0}>
                <Col lg={12} className="h3">
                    Optional inputs
                </Col>
                <Col lg={12}>
                    <i>
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        These variables have default values and don't have to be set to use this module. You may set
                        these variables to override their
                        default values.
                    </i>
                </Col>

                {optional_inputs.map((input) => (
                    <Row key={`input-${input.id}`} className="mt-3">
                        <Col lg={12} className="pb-1">
                            <span className="fw-bold">{input.attributes.name}</span>{' '}
                            <code>{input.attributes.type ? ` (${input.attributes.type})` : ''}</code>
                        </Col>
                        <Col lg={12}>
                            <Markdown>{input.attributes.description}</Markdown>
                        </Col>
                    </Row>
                )
                )}
            </Row>
        </Col>
    );
};
