import { createContext } from 'react';

export type FavoriteContextType = {
    getFavoriteItems: (listId: number) => number[];
    removeFavoriteItem: (listId: number, itemId: number) => void;
    addFavoriteItem: (listId: number, itemId: number) => void;
};

export const defaultContext: FavoriteContextType = {
    getFavoriteItems: () => [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeFavoriteItem: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    addFavoriteItem: () => {},
};

export const FavoriteContext = createContext<FavoriteContextType>(defaultContext);
