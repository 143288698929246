import { combineReducers } from 'redux';
import { createSelectorHook } from 'react-redux';
import { moduleListReducer } from './moduleList';
import { themeReducer } from './theme';

const rootReducer = combineReducers({
    moduleList: moduleListReducer,
    theme: themeReducer
});

export type RootState = ReturnType<typeof rootReducer>;

// @ts-ignore
export const useTypedSelector = createSelectorHook<RootState>();

export default rootReducer;
