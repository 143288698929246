import React, { FC, ReactNode, MouseEvent, FocusEvent } from 'react';

const isUrlComponent = (url: string) => (url.startsWith('/') || url.startsWith('#') || url.startsWith('?'));

const isModifiedEvent = (e: MouseEvent<HTMLAnchorElement>) => (
    e.defaultPrevented || e.metaKey || e.ctrlKey || e.shiftKey || e.altKey || e.button === 1
);

type TargetType = '_blank' | '_self' | '_parent' | '_top' | string;

type RelType =
  'alternate' |
  'author' |
  'bookmark' |
  'external' |
  'help' |
  'license' |
  'next' |
  'nofollow' |
  'noreferrer' |
  'noopener' |
  'noopener noreferrer' |
  'noreferrer noopener' |
  'prev' |
  'search' |
  'tag'
  ;

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFn = () => {};

export type RawLinkPropsType = {
    readonly href?: string;
    readonly className?: string;
    readonly target?: TargetType;
    readonly rel?: RelType;
    readonly onClick?: (e: MouseEvent) => void;
    readonly onFocus?: (e: FocusEvent) => void;
    readonly onBlur?: (e: FocusEvent) => void;
    readonly children?: ReactNode;
};

export const RawLink: FC<RawLinkPropsType> = ({
    href,
    children,
    target = '_self',
    onClick = emptyFn,
    ...restProps
}) => {
    const isAbsolute = href && (href.startsWith('//') || !isUrlComponent(href));

    return (
        <a
            href={href}
            target={target}
            onClick={(e) => {
                if (!isAbsolute && target === '_self' && !isModifiedEvent(e)) {
                    onClick(e);

                    e.preventDefault();
                }
            }}
            {...restProps}
        >
            {children}
        </a>
    );
};
