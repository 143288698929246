import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './index.scss';

const NotFoundPage = () => {
    const [link, setLink] = useState('click here to go home');
    const [error, setError] = useState('404 page not found');

    const setCharAt = (str, index, chr) => {
        if (index > str.length - 1) return str;
        return str.substr(0, index) + chr + str.substr(index + 1);
    };

    useEffect(() => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-=+<>,./?[{()}]!@#$%^&*~`|'.split('');
        const totalError = error.length,
            totalLink = link.length;
        let progressError = 0,
            progressLink = 0;
        const scrambleInterval = setInterval(() => {
            let string404 = error;
            let stringLink = link;

            for (let i = 0; i < totalError; i++) {
                if (i >= progressError) {
                    string404 = setCharAt(string404, i, characters[Math.round(Math.random() * (characters.length - 1))]);
                }
            }

            for (let i = 0; i < totalLink; i++) {
                if (i >= progressLink) {
                    stringLink = setCharAt(stringLink, i, characters[Math.round(Math.random() * (characters.length - 1))]);
                }
            }
            setError(() => string404);
            setLink(() => stringLink);
        }, 10);

        setTimeout(() => {
            const revealInterval = setInterval(() => {
                if (progressError < totalError) {
                    progressError++;
                } else if (progressLink < totalLink) {
                    progressLink++;
                } else {
                    clearInterval(revealInterval);
                    clearInterval(scrambleInterval);
                }
            }, 50);
        }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className="text__error">{error}</div>
            <div className="text__link">
                <Link to="/">{link}</Link>
            </div>
        </div>
    );
};

export default NotFoundPage;
