import { FavoritesStateType } from './types';
import {
    ActionsType,
    isAddFavoriteAction,
    isAddFavoriteListAction, isInitFavoriteAction,
    isRemoveFavoriteAction,
    isRemoveFavoriteListAction, isRenameFavoriteListAction
} from './actions';

export const favoritesReducer = (state: FavoritesStateType, action: ActionsType) => {
    if (isRemoveFavoriteAction(action)) {
        const favoriteListPos = state.favorites.findIndex((favoriteList) => favoriteList.id === action.payload.listId);

        if (favoriteListPos > -1) {
            const newFavorites = [...state.favorites];
            const currentFavoriteList = newFavorites[favoriteListPos];
            const itemPos = currentFavoriteList.items.indexOf(action.payload.itemId);

            if (itemPos > -1) {
                const newItems = [...currentFavoriteList.items];

                newItems.splice(itemPos, 1);

                currentFavoriteList.items = newItems;
                newFavorites[favoriteListPos] = currentFavoriteList;

                return {
                    ...state,
                    favorites: newFavorites,
                }
            }
        }
    }

    if (isAddFavoriteAction(action)) {
        const favoriteListPos = state.favorites.findIndex((favoriteList) => favoriteList.id === action.payload.listId);

        if (favoriteListPos > -1) {
            const newFavorites = [...state.favorites];
            const currentFavoriteList = newFavorites[favoriteListPos];
            const itemPos = currentFavoriteList.items.indexOf(action.payload.itemId);

            if (itemPos === -1) {
                currentFavoriteList.items = [...currentFavoriteList.items, action.payload.itemId];
                newFavorites[favoriteListPos] = currentFavoriteList;

                return {
                    ...state,
                    favorites: newFavorites,
                }
            }
        }
    }

    if (isAddFavoriteListAction(action)) {
        return {
            ...state,
            autoIncrement: state.autoIncrement + 1,
            favorites: [
                ...state.favorites,
                {
                    id: state.autoIncrement + 1,
                    name: action.payload.name,
                    type: action.payload.type,
                    items: action.payload.items
                }
            ],
        }
    }

    if (isRemoveFavoriteListAction(action)) {
        const favoriteListPos = state.favorites.findIndex((favoriteList) => favoriteList.id === action.payload.listId);

        if (favoriteListPos > -1) {
            const newFavorites = [...state.favorites];

            newFavorites.splice(favoriteListPos, 1);

            return {
                ...state,
                favorites: newFavorites,
            }
        }
    }


    if (isRenameFavoriteListAction(action)) {
        const favoriteListPos = state.favorites.findIndex((favoriteList) => favoriteList.id === action.payload.listId);

        if (favoriteListPos > -1) {
            const newFavorites = [...state.favorites];

            newFavorites[favoriteListPos] = {
                ...newFavorites[favoriteListPos],
                name: action.payload.name,
            };

            return {
                ...state,
                favorites: newFavorites,
            }
        }
    }

    if (isInitFavoriteAction(action)) {
        return action.payload.state;
    }

    throw Error('Unknown action: ' + action.type);
}

