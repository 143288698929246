import React, { FC } from 'react';
import { BadgeType } from './types';
import { BadgeIcon } from './badgeIcon';
import { Icon } from '../Icon/Icon';

import './styles.scss';
import { classNames } from '../../utils/classNames';

type BadgeFilterTitlePropsType = {
    readonly title?: string;
    readonly badge: BadgeType;
    readonly selected?: boolean;
}

export const BadgeFilterTitle: FC<BadgeFilterTitlePropsType> = ({ title, badge, selected }) => (
    <div className={classNames('title', ['selected', selected])}>
        <BadgeIcon badge={badge} />
        {title || badge.title}
        {selected && (
            <div className="selected-icon">
                <Icon id="check-small" />
            </div>
        )}
    </div>
);
