import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import API from '../../api';
import { ModuleCardPropsType, ModuleExtendedListStateType } from './types';
import { transformModulesData } from './utils';
import { ModuleCard } from './moduleCard';
import { FavoriteContext } from '../Favorite/favoriteContext';
import { FAVORITE_MODULES_LIST_ID } from '../Favorite/useFavorite';
import { explodeList } from '../common';

const emptyFavoriteList: number[] = [];

export const ModuleList = () => {
    const [state, setState] = React.useState<ModuleExtendedListStateType>({
        items: [],
        page: 0,
        hasMore: true
    });
    // eslint-disable-next-line
    const [filterParams] = useSearchParams();
    const { getFavoriteItems } = useContext(FavoriteContext);
    const favoriteItems = getFavoriteItems(FAVORITE_MODULES_LIST_ID);
    const showFavoriteOnly = filterParams.get('favorite') === '1';
    const favorite = showFavoriteOnly ? favoriteItems : emptyFavoriteList;

    React.useEffect(() => {
        fetchMoreData(true);
    }, [filterParams, favorite]);

    const fetchMoreData = (reset = false) => {
        const page = reset ? 0 : state.page;
        let apiURL = `/modules?page=` + (page + 1);
        apiURL += `&include=` + encodeURIComponent('latest-version,source-participation');
        const provider = filterParams.get('provider');
        const badges = explodeList(filterParams.get('badges'));
        const query = filterParams.get('query');

        if (showFavoriteOnly) {
            apiURL += `&filter[id]=` + encodeURIComponent(favoriteItems.join(','));
        }
        if (provider !== null) {
            apiURL += `&filter[provider]=` + encodeURIComponent(provider);
        }
        if (badges.length > 0) {
            apiURL += `&filter[badges]=` + encodeURIComponent(badges.join(','));
        }
        if (query) {
            apiURL += `&filter[query]=` + encodeURIComponent(query);
        } else {
            // Always sort by the downloads except when the filter query is present, otherwise sort by the search relevance.
            apiURL += `&sort=` + encodeURIComponent('-downloads');
        }
        API.get(apiURL)
            .then((response) => {
                const r = response.data;

                setState({
                    items: reset ? transformModulesData(r) : state.items?.concat(transformModulesData(r)),
                    page: r['meta']['pagination']['current-page'],
                    hasMore: r['meta']['pagination']['next-page'] != null
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Row>
            <InfiniteScroll
                dataLength={state.items ? state.items.length : 0}
                next={fetchMoreData}
                hasMore={state.hasMore}
                loader={<div className="text-center">Loading...</div>}
            >
                {state.items?.map((props: ModuleCardPropsType) => <ModuleCard key={`module-${props.module.id}`} {...props} />)}
            </InfiniteScroll>
            <Row hidden={!state.items || state.items.length !== 0 || state.hasMore} className="px-0 mx-0">
                <Col lg={12} className="rounded-pane-bottom">
                    No records found for your search
                </Col>
            </Row>
        </Row>
    );
};


