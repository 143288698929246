import React, { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { buildModuleUrl } from './utils';
import { SubmoduleDataType } from './types';

type SubModulesMenuPropsType = {
    readonly namespace: string;
    readonly name: string;
    readonly provider: string;
    readonly version: string;
    readonly moduleVersionId?: number;
    readonly listIds: number[],
    readonly type: 'examples' | 'submodules';
    readonly submodules?: {[p: number]: SubmoduleDataType};
    readonly onSelectSubModuleHandler: (id: number | null) => void;
}

export const SubModulesMenu: FC<SubModulesMenuPropsType> = ({
    namespace,
    name,
    version,
    provider,
    moduleVersionId,
    listIds,
    type,
    submodules,
    onSelectSubModuleHandler,
}) => (
    <Dropdown.Menu renderOnMount>
        {
            submodules &&
            listIds &&
            listIds.map((id) => {
                if (submodules[id]) {
                    return (
                        <Dropdown.Item
                            key={`${type}-selector-${moduleVersionId}-${id}`}
                            href={
                                buildModuleUrl(
                                    String(namespace),
                                    name,
                                    String(provider),
                                    String(version),
                                    id,
                                    submodules,
                                    type === 'examples',
                                )
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                onSelectSubModuleHandler(id);
                            }}
                        >
                            {submodules[id].attributes.name}
                        </Dropdown.Item>
                    );
                }

                return undefined;
            })}
    </Dropdown.Menu>
);
