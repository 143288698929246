import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Dropdown, Form } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';

import API from '../../api';
import { ModuleSearchItemType, ProviderDocumentationSearchItemType, ProviderSearchItemType } from '../resourceTypes';
import { recursiveToCamel } from '../common';
import { LiaStroopwafelSolid } from 'react-icons/lia';
import { PiParallelogram } from 'react-icons/pi';
import { GoChevronRight } from 'react-icons/go';
import { IoSearch } from 'react-icons/io5';

export type SearchDataType = (ProviderSearchItemType | ModuleSearchItemType | ProviderDocumentationSearchItemType)[];

type GlobalSearchPropsType = {
    readonly autoFocus?: boolean
};

export const GlobalSearch: FC<GlobalSearchPropsType> = ({ autoFocus = false }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get('query');
    const [queryText, setQueryText] = useState(query);
    const [searchData, setSearchData] = useState<SearchDataType | null>(null);
    // eslint-disable-next-line
    const searchRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [show, setShow] = useState(true);

    const [providers, resources, modules] = useMemo(() => {
        const p: ProviderSearchItemType[] = [],
            m: ModuleSearchItemType[] = [],
            r: ProviderDocumentationSearchItemType[] = [];
        if (searchData !== null) {
            for (const i of searchData) {
                if (i.type === 'providers') {
                    p.push(i);
                } else if (i.type === 'modules') {
                    m.push(i);
                } else if (i.type === 'resources') {
                    r.push(i);
                }
            }
        }
        return [p, r, m];
    }, [searchData]);

    const setFilter = () => {
        setQueryText(searchRef.current ? searchRef.current.value : '');
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!!queryText && queryText !== query) {
                API.get(`/search?query=` + encodeURIComponent(queryText))
                    .then((response) => {
                        setSearchData(recursiveToCamel(response.data.data));
                        if (!show) {
                            setShow(true);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setSearchData(null);
                if (!queryText && !!query) {
                    setSearchParams((p) => {
                        p.delete('query');
                        return p;
                    });
                }
            }
        }, 400);

        return () => clearTimeout(timer);
    }, [queryText]);

    useEffect(() => {
        setQueryText(query);
    }, [query]);

    const showAll = (url: string) => {
        navigate(`${url}` + `?query=` + encodeURIComponent(queryText ?? ''));
        setSearchData(null);
    };

    return (
        <Dropdown
            className="w-100"
            autoClose
            show={show}
            align="end"
            onToggle={(_, meta) => {
                if (meta.originalEvent && meta.originalEvent.target !== searchRef.current) {
                    setShow(false);
                }
            }}
        >
            {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
            <IconContext.Provider value={{ className: 'react-icons' }}>
                <IoSearch className="global-search-icon" />
                <Form onSubmit={() => showAll('/providers')}>
                    <Form.Control
                        ref={searchRef}
                        className="search"
                        type="text"
                        autoFocus={autoFocus}
                        placeholder="Search all resources"
                        onChange={setFilter}
                        value={queryText ?? ''}
                    />
                </Form>

                <Dropdown.Menu className="w-100 global-search-menu" hidden={searchData === null}>
                    {(providers.length > 0 || resources.length > 0) && (
                        <>
                            <Dropdown.Item
                                onClick={() => showAll('/providers')}
                                key="all-providers"
                                className="d-flex justify-content-between"
                                hidden={providers.length === 0 && resources.length === 0}
                            >
                                <div className="h5 fw-bold">
                                    <LiaStroopwafelSolid />
                                    Providers
                                </div>
                                <span className="fw-bold">
                                    See all <GoChevronRight />
                                </span>
                            </Dropdown.Item>
                        </>
                    )}

                    {providers.map((item) => (
                        <Dropdown.Item href={item.attributes.url} key={item.id}>
                            <Col>{item.attributes.fullName}</Col>
                            <Col className="text-muted text-truncate-2">
                                <small>{item.attributes.description}</small>
                            </Col>
                        </Dropdown.Item>
                    ))}

                    {resources.map((item) => (
                        <Dropdown.Item href={item.attributes.url} key={item.id}>
                            <Col>
                                <span>Resource:</span> {item.attributes.fullName}
                            </Col>
                            <Col className="text-muted text-truncate-2">
                                <small>{item.attributes.description}</small>
                            </Col>
                        </Dropdown.Item>
                    ))}

                    {modules.length > 0 && (
                        <>
                            {(providers.length > 0 || resources.length > 0) && <Dropdown.Divider />}
                            <Dropdown.Item onClick={() => showAll('/modules')} key="all-modules" className="d-flex justify-content-between">
                                <div className="h5 fw-bold">
                                    <PiParallelogram />
                                    Modules
                                </div>
                                <span className="fw-bold">
                                    See all <GoChevronRight />
                                </span>
                            </Dropdown.Item>
                        </>
                    )}

                    {modules.map((item) => (
                        <Dropdown.Item href={item.attributes.url} key={item.id}>
                            <Col>
                                {item.attributes.fullName.replace(/\/[\w-]+$/i, '')}
                                <span className="text-muted ps-1">
                                    <small>({item.attributes.fullName.replace(/^[\w-]+\/[\w-]+\//i, '')} provider)</small>
                                </span>
                            </Col>
                            <Col className="text-muted text-truncate-2">
                                <small>{item.attributes.description}</small>
                            </Col>
                        </Dropdown.Item>
                    ))}
                    {searchData !== null && searchData.length === 0 &&
                        <Dropdown.Item disabled>No results found</Dropdown.Item>}
                </Dropdown.Menu>
            </IconContext.Provider>
        </Dropdown>
    );
};

export default GlobalSearch;
