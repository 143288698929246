import React, { useRef } from 'react';
import { RouteProps } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IconContext } from 'react-icons';

import API from '../../api';

import * as tp from './types';
import { defaultTitle } from '../constants';
import { recursiveToCamel } from '../common';
import DownloadsSummary from '../DownloadsSummary';
import ConfigurationInstructions from '../ConfigurationInstructions';
import SourceInfo from '../SourceInfo';
import SourceTopLabels from '../SourceTopLabels';
import { ModuleTabs } from './moduleTabs';
import { ModuleHeader } from './moduleHeader';

const initialModuleState = (namespace: string | undefined, name: string | undefined, provider: string | undefined, version: string | undefined) => ({
    namespace: namespace ?? '',
    name: name ?? '',
    provider: provider ?? '',
    version: version ?? '',
    data: null,
    moduleVersion: null,
    allVersions: [],
    moduleDownloads: null,
    sourceInfo: null,
    sourceParticipation: null,
    changelog: null,
    topLabels: null
});

const initialModuleVersionState = {
    data: null,
    submoduleIds: [],
    exampleIds: [],
    rootSubmoduleId: null,
    selectedSubmoduleId: null
};

const initialSubModuleState = {
    readme: null,
    inputIds: [],
    outputIds: [],
    resourceIds: [],
    providerDependencyIds: [],
    dependencyIds: []
};

const ModulePage: React.FC<RouteProps> = () => {
    const { namespace, name, provider, version, target, submoduleName } = useParams();
    const [, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const titleRef = useRef('');

    const [state, setState] = React.useState<tp.ModulePageStateType>(
        initialModuleState(namespace, name, provider, version)
    );

    const [moduleVersionState, setModuleVersionState] = React.useState<tp.ModuleVersionStateType>(initialModuleVersionState);

    const [submoduleState, setSubmoduleState] = React.useState<tp.SubmoduleStateType>(initialSubModuleState);

    if (!!target && target !== 'submodules' && target !== 'examples') {
        navigate('/NotFound');
    }

    const scrollIntoView = () => {
        if (!location.hash) {
            return;
        }
        const node = document.querySelector(`[id='${location.hash.substring(1)}']`);
        if (node) {
            node.scrollIntoView();
        }
    };

    React.useEffect(() => {
        setState(initialModuleState(namespace, name, provider, version));
        setModuleVersionState(initialModuleVersionState);
        setSubmoduleState(initialSubModuleState);

        API.get(`/modules/${namespace}/${name}/${provider}`)
            .then((response) => {
                const r = recursiveToCamel(response.data);
                let [moduleVersion, downloadsSummary, sourceInfo, changelog, topLabels, sourceParticipation, potentialForkOf] = [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    undefined
                ];
                const allVersions = [];
                for (const d of r.included) {
                    if (!moduleVersion && d.type === 'module-versions') {
                        if (state.version === 'latest' && 'latestVersion' in r.data.relationships && d.id === r.data.relationships.latestVersion.data.id) {
                            moduleVersion = d;
                        } else if (state.version === d.attributes.version) {
                            moduleVersion = d;
                        }
                    }
                    if (!downloadsSummary && d.type === 'module-downloads') {
                        downloadsSummary = d;
                    } else if (!sourceInfo && d.type === 'source-info') {
                        sourceInfo = d;
                    } else if (!changelog && d.type === 'source-changelog') {
                        changelog = d;
                    } else if (!topLabels && d.type === 'source-top-labels') {
                        topLabels = d;
                    } else if (!sourceParticipation && d.type === 'source-participation') {
                        sourceParticipation = d;
                    } else if (!potentialForkOf && d.type === 'modules') {
                        potentialForkOf = d;
                    }
                    if (d.type === 'module-versions') {
                        allVersions.push(d);
                    }
                }

                setState({
                    ...state,
                    data: r,
                    moduleVersion: moduleVersion,
                    allVersions: allVersions,
                    moduleDownloads: downloadsSummary,
                    sourceInfo: sourceInfo,
                    sourceParticipation: sourceParticipation,
                    topLabels: topLabels,
                    changelog: changelog,
                    potentialForkOf: potentialForkOf
                });

                // SEO title
                document.title =
                    (!!target && !!submoduleName ? submoduleName + (target === 'examples' ? ' Example' : ' Submodule') + ` | ` : '') +
                    `${r.data.attributes.fullName} | Modules | ${defaultTitle}`;

                titleRef.current = document.title;
            })
            .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                    navigate('/NotFound');
                }
            });
    }, [namespace, name, provider]);

    React.useEffect(() => {
        API.get(`/modules/${namespace}/${name}/${provider}/versions/${version}`)

            .then((response) => {
                const sortSubmoduleIdByName = function (a: number, b: number) {
                    if (newIncluded.submodules[a].attributes.name > newIncluded.submodules[b].attributes.name) {
                        return 1;
                    } else if (newIncluded.submodules[a].attributes.name < newIncluded.submodules[b].attributes.name) {
                        return -1;
                    }
                    return 0;
                };

                const r = recursiveToCamel(response.data);
                const exampleIds: number[] = [];
                const submoduleIds: number[] = [];
                const newIncluded: tp.ModuleVersionIncludedType = {
                    submodules: {},
                    inputs: {},
                    outputs: {},
                    resources: {},
                    dependencies: {},
                    providerDependencies: {}
                };

                let rootSubmoduleId: number | null = null;
                let selectedSubmoduleId: number | null = null;

                if (r.data.relationships.root.data) {
                    rootSubmoduleId = r.data.relationships.root.data.id;
                }

                for (const d of r.data.relationships.examples.data)
                    if (rootSubmoduleId !== d.id) {
                        exampleIds.push(d.id);
                    }

                for (const d of r.data.relationships.submodules.data)
                    if (rootSubmoduleId !== d.id) {
                        submoduleIds.push(d.id);
                    }

                for (const d of r.included) {
                    if (d.type === 'submodules') {
                        newIncluded.submodules[d.id] = d;
                        if (
                            !selectedSubmoduleId &&
                            submoduleName &&
                            d.attributes.name === submoduleName &&
                            ((target === 'submodules' && submoduleIds.includes(d.id)) || (target === 'examples' && exampleIds.includes(d.id)))
                        ) {
                            selectedSubmoduleId = d.id;
                        }
                    } else if (d.type === 'inputs') {
                        newIncluded.inputs[d.id] = d;
                    } else if (d.type === 'outputs') {
                        newIncluded.outputs[d.id] = d;
                    } else if (d.type === 'provider-dependencies') {
                        newIncluded.providerDependencies[d.id] = d;
                    } else if (d.type === 'dependencies') {
                        newIncluded.dependencies[d.id] = d;
                    } else if (d.type === 'resources') {
                        newIncluded.resources[d.id] = d;
                    }
                }

                if (submoduleIds.length > 1) {
                    submoduleIds.sort(sortSubmoduleIdByName);
                }

                if (exampleIds.length > 1) {
                    exampleIds.sort(sortSubmoduleIdByName);
                }

                if (!!target && !!submoduleName && !selectedSubmoduleId) {
                    navigate('/NotFound');
                }

                delete r.included;
                r.included = newIncluded;

                const newState = {
                    ...moduleVersionState,
                    data: r,
                    submoduleIds: submoduleIds,
                    exampleIds: exampleIds,
                    rootSubmoduleId: rootSubmoduleId,
                    selectedSubmoduleId: selectedSubmoduleId
                };

                setModuleVersionState(newState);

                setSubmoduleRelationships(newState);

                setTimeout(scrollIntoView, 100);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [namespace, name, provider, version]);

    const getModuleVersionRelations = function (): tp.ModuleVersionIncludedType | null {
        if (moduleVersionState.data) {
            return moduleVersionState.data.included;
        }

        return null;
    };

    const setSubmoduleRelationships = function (nextModuleVersionState: tp.ModuleVersionStateType) {
        const targetSubmoduleId = nextModuleVersionState.selectedSubmoduleId ?? nextModuleVersionState.rootSubmoduleId,
            inputIds = [],
            outputIds = [],
            resourceIds = [],
            providerDependencyIds = [],
            dependencyIds = [];

        if (nextModuleVersionState.data && targetSubmoduleId) {
            const relationships = nextModuleVersionState.data.included.submodules[targetSubmoduleId].relationships;
            if (relationships) {
                if (Array.isArray(relationships.inputs.data)) {
                    for (const d of relationships.inputs.data) {
                        inputIds.push(d.id);
                    }
                }
                if (Array.isArray(relationships.outputs.data)) {
                    for (const d of relationships.outputs.data) {
                        outputIds.push(d.id);
                    }
                }
                if (Array.isArray(relationships.resources.data)) {
                    for (const d of relationships.resources.data) {
                        resourceIds.push(d.id);
                    }
                }
                if (Array.isArray(relationships.providerDependencies.data)) {
                    for (const d of relationships.providerDependencies.data) {
                        providerDependencyIds.push(d.id);
                    }
                }
                if (Array.isArray(relationships.dependencies.data)) {
                    for (const d of relationships.dependencies.data) {
                        dependencyIds.push(d.id);
                    }
                }
            }

            setSubmoduleState({
                readme: nextModuleVersionState.data.included.submodules[targetSubmoduleId].attributes.readme,
                inputIds: inputIds,
                outputIds: outputIds,
                resourceIds: resourceIds,
                dependencyIds: dependencyIds,
                providerDependencyIds: providerDependencyIds
            });
        } else {
            setSubmoduleState({
                readme: null,
                inputIds: [],
                outputIds: [],
                resourceIds: [],
                dependencyIds: [],
                providerDependencyIds: []
            });
        }
    };

    const setVersion = function (nextVersion: string) {
        let moduleVersion: any = null;
        if (state.data) {
            for (const d of state.data.included) {
                if (!moduleVersion && d.type === 'module-versions') {
                    if (
                        nextVersion === 'latest' &&
                        'latestVersion' in state.data.data.relationships &&
                        d.id === state.data.data.relationships.latestVersion.data.id
                    ) {
                        moduleVersion = d;
                    } else if ('attributes' in d && nextVersion === (d.attributes as any).version) {
                        moduleVersion = d;
                    }
                }
            }
        }
        setState({
            ...state,
            version: nextVersion,
            moduleVersion: moduleVersion
        });
        setModuleVersionState({
            ...moduleVersionState,
            selectedSubmoduleId: null
        });

        setSearchParams((params) => {
            params.delete('tab');
            return params;
        });

        navigate(`/modules/${state.namespace}/${state.name}/${state.provider}/${nextVersion}`);
    };

    const setSelectedSubmoduleId = function (submoduleId: number | null) {
        const relationships = getModuleVersionRelations();
        if (relationships) {
            const newState = {
                ...moduleVersionState,
                selectedSubmoduleId: submoduleId
            };

            setModuleVersionState(newState);
            setSubmoduleRelationships(newState);

            // Updating the URL without making API request to the backend.
            setSearchParams((params) => {
                params.delete('tab');

                return params;
            });

            if (submoduleId) {
                const t = moduleVersionState.exampleIds.includes(submoduleId) ? 'examples' : 'submodules';
                navigate(
                    `/modules/${state.namespace}/${state.name}/${state.provider}/${state.version}/${t}/${relationships?.submodules[submoduleId].attributes.name}`
                );
                // SEO title
                document.title =
                    relationships?.submodules[submoduleId].attributes.name +
                    (t === 'examples' ? ' Example' : ' Submodule') +
                    ` | ` +
                    `${state.data?.data.attributes.fullName} | Modules | ${defaultTitle}`;
            } else {
                navigate(`/modules/${state.namespace}/${state.name}/${state.provider}/${state.version}`);

                // SEO title
                document.title = `${state.data?.data.attributes.fullName} | Modules | ${defaultTitle}`;
            }

            titleRef.current = document.title;
        }
    };

    const getProvisionInstructions = () => {
        const relationships = getModuleVersionRelations();
        const moduleName = moduleVersionState.selectedSubmoduleId
            ? name + '_' + relationships?.submodules[moduleVersionState.selectedSubmoduleId].attributes.name
            : name;
        let isExamples = false;
        let source = namespace + '/' + name + '/' + provider;
        if (moduleVersionState.selectedSubmoduleId !== null) {
            isExamples = moduleVersionState.exampleIds.includes(moduleVersionState.selectedSubmoduleId);
            source +=
                '//' + (isExamples ? 'examples' : 'modules') + '/' + relationships?.submodules[moduleVersionState.selectedSubmoduleId].attributes.name;
        }
        return (
            'module "' + moduleName + '" {\n' + '  source = "' + source + '"\n' + '  version = "' + state.moduleVersion?.attributes.version + '"\n' + '}\n'
        );
    };

    const isReady = !!state
        && state.data?.data.attributes.name === name
        && state.data?.data.attributes.namespace === namespace;

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <IconContext.Provider value={{ className: 'react-icons' }}>
            <Row hidden={!isReady}>
                {state.data && isReady && (
                    <Col lg={9} className="pe-md-4">
                        <ModuleHeader
                            moduleVersionData={moduleVersionState.data}
                            moduleId={state.data.data.id}
                            module={state.data.data.attributes}
                            selectedSubmoduleId={moduleVersionState.selectedSubmoduleId}
                            name={state.data.data.attributes.name}
                            moduleTitle={titleRef.current}
                            submoduleIds={moduleVersionState.submoduleIds}
                            rootSubmoduleId={moduleVersionState.rootSubmoduleId}
                            moduleVersionId={state.moduleVersion?.id}
                            exampleIds={moduleVersionState.exampleIds}
                            potentialForkOf={state.potentialForkOf}
                            sourceParticipationData={state.sourceParticipation?.attributes.data.all}
                            forkedFrom={state.sourceInfo?.attributes.forkedFrom || ''}
                            submoduleReadme={submoduleState.readme}
                            onSelectSubModuleHandler={setSelectedSubmoduleId}
                        />
                        <ModuleTabs
                            moduleId={state.data.data.id}
                            moduleFullName={state.data.data.attributes.fullName}
                            moduleVersionData={moduleVersionState.data}
                            subModuleOutputIds={submoduleState.outputIds}
                            subModuleInputIds={submoduleState.inputIds}
                            subModuleResourceIds={submoduleState.resourceIds}
                            subModuleDependencyIds={submoduleState.dependencyIds}
                            subModuleProviderDependencyIds={submoduleState.providerDependencyIds}
                            allVersions={state.allVersions}
                            changelog={state.changelog}
                            exampleIds={moduleVersionState.exampleIds}
                            readme={submoduleState.readme}
                            selectedSubmoduleId={moduleVersionState.selectedSubmoduleId}
                            forksCount={state.data?.data.attributes.forks}
                            onSetVersion={setVersion}
                        />
                    </Col>
                )}
                <Col lg={3} className="pe-4">
                    <Row className="rounded-pane pane-shadow px-4 pb-2 mb-4">
                        <Col lg={12} className="pb-2">
                            <h5 className="fw-bold">Provision instructions</h5>
                        </Col>
                        <Col lg={12} className="p-0">
                            { isReady && (
                                <ConfigurationInstructions
                                    tofuInfo={state.data?.data.attributes.isInTofuRegistry ? (
                                        <>
                                            Click to copy this code and paste into your OpenTofu configuration, insert the
                                            variables, and run <code>tofu init</code>:
                                        </>
                                    ) : undefined}
                                    terraformInfo={
                                        <>
                                            Click to copy this code and paste into your OpenTofu configuration, insert the
                                            variables, and run <code>terraform init</code>:
                                        </>
                                    }
                                >
                                    {getProvisionInstructions()}
                                </ConfigurationInstructions>
                            )}
                        </Col>
                    </Row>

                    <Row
                        hidden={state.moduleDownloads == null || moduleVersionState.selectedSubmoduleId !== null}
                        className="rounded-pane pane-shadow px-4 mb-4"
                    >
                        <Col lg={12} className="pb-2">
                            <h5 className="fw-bold">Module downloads</h5>
                        </Col>
                        <DownloadsSummary
                            week={state.moduleDownloads?.attributes.week}
                            month={state.moduleDownloads?.attributes.month}
                            year={state.moduleDownloads?.attributes.year}
                            total={state.moduleDownloads?.attributes.total}
                        />
                    </Row>

                    {!!state.data && !!state.sourceInfo && !moduleVersionState.selectedSubmoduleId && (
                        <Row className="rounded-pane pane-shadow px-4 mb-4">
                            <Col lg={12} className="pb-2">
                                <h5 className="fw-bold">Repository</h5>
                            </Col>
                            <SourceInfo data={state.sourceInfo} sourceURL={state.data.data.attributes.source} />
                        </Row>
                    )}

                    {!!state.data && !!state.topLabels && !moduleVersionState.selectedSubmoduleId && (
                        <Row className="rounded-pane pane-shadow px-4 mb-4">
                            <Col lg={12} className="pb-2">
                                <h5 className="fw-bold">Top Labels</h5>
                            </Col>
                            <SourceTopLabels data={state.topLabels} sourceURL={state.data.data.attributes.source} />
                        </Row>
                    )}
                </Col>
            </Row>
        </IconContext.Provider>
    );
};

export default ModulePage;
