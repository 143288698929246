import React, { useEffect, useState } from 'react';
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri';

import { SubCategoryNode } from './subCategoryNode';
import { wordWrapByUnderscores, adjustResourceName } from './utils';
import { DocSubcategory, ProviderDocumentationResourceType } from "./types";
import {RawLink} from "../RawLink";
import { Highlight } from './highlight';

export type CategoryNodeProps = {
    readonly sub: DocSubcategory;
    readonly providerName: string;
    readonly selected: ProviderDocumentationResourceType;
    readonly selectedCat: string | null;
    readonly selectedSub: string | null;
    readonly expanded: boolean;
    readonly highlightedText: string;
    readonly selectDoc: (doc: ProviderDocumentationResourceType) => void;
    readonly getDocUrl: (doc: ProviderDocumentationResourceType) => string;
};
export const CategoryNode: React.FC<CategoryNodeProps> = ({
    sub,
    selected,
    selectedSub,
    selectedCat,
    expanded,
    highlightedText,
    providerName,
    selectDoc,
    getDocUrl,
}) => {
    const [open, setOpen] = useState(sub.title === selectedCat || expanded);

    useEffect(() => {
        setOpen(sub.title === selectedCat || expanded);
    }, [expanded, sub.title, selectedCat]);

    return (
        <li key={`li-cat-${sub.title}`} className="ms-2 ps-2">
            <span
                className="d-flex py-2 top-level"
                onClick={() => setOpen(!open)}
            >
                <span className="d-block position-relative" style={{ marginLeft: '-1em' }}>
                    {open ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
                </span>
                <Highlight text={sub.title} searchString={highlightedText} capitalize />
            </span>
            <ul key={`ul-cat-${sub.title}`} className="nav nav-link p-0 ps-1" hidden={!open}>
                {sub.items.map((cat) => {
                    if (cat.title === '') {
                        return cat.items.map((doc) => (
                            <li key={`doc-${doc.id}`} className="ps-2 border-start">
                                <RawLink
                                    href={getDocUrl(doc)}
                                    className={`d-flex py-2 ${!!selected && selected.id === doc.id ? 'active' : ''}`}
                                    onClick={() => selectDoc(doc)}
                                >
                                    <Highlight
                                        text={wordWrapByUnderscores(
                                            doc.attributes.category !== 'guides'
                                                ? adjustResourceName(doc.attributes.title, providerName)
                                                : doc.attributes.title,
                                        )}
                                        searchString={wordWrapByUnderscores(highlightedText)}
                                    />
                                </RawLink>
                            </li>
                        ));
                    } else {
                        return (
                            <SubCategoryNode
                                key={`cat-${sub.title}//${cat.title}`}
                                cat={cat}
                                sub={sub}
                                selected={selected}
                                providerName={providerName}
                                selectedCat={selectedCat}
                                selectedSub={selectedSub}
                                highlightedText={highlightedText}
                                expanded={expanded}
                                getDocUrl={getDocUrl}
                                selectDoc={selectDoc}
                            />
                        );
                    }
                })}
            </ul>
        </li>
    );
};

export default CategoryNode;
