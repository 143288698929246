import React, { FC, useMemo } from 'react';
import { BadgeGroupEnum, BadgeGroupMapType, BadgeSubject, BadgeType, getBadgesConfig } from './types';
import { Badge } from './badge';

import './styles.scss';

export type BadgesPropsType = {
    readonly badges: string[];
    readonly subject: BadgeSubject;
}

export const Badges: FC<BadgesPropsType> = ({ badges, subject }) => {
    const allBudges = getBadgesConfig(subject);

    const existingBudgesConfig = useMemo(() => (
        allBudges.filter((config) => badges.includes(config.type))
    ),  [badges, allBudges]);

    const groupedBadgesConfig: BadgeType[] = [];
    const badgeGroupMap: BadgeGroupMapType = {};

    for (const conf of existingBudgesConfig) {
        const index = badgeGroupMap[conf.group];

        if (index !== undefined) {
            if (groupedBadgesConfig[index].level < conf.level) {
                groupedBadgesConfig[index] = conf;
            }
        } else {
            badgeGroupMap[conf.group] = groupedBadgesConfig.length;
           
            groupedBadgesConfig.push(conf);
        }
    }


    return (
        <div className="all-badges">
            {
                groupedBadgesConfig.map((badge) => <Badge key={badge.type} badge={badge} />)
            }
        </div>
    );
}
