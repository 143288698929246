import { SET_SITE_THEME } from '../actionTypes';

export type ThemeState = {
    theme: 'auto' | 'dark' | 'light' | string | null;
};

export function themeReducer(
    state: ThemeState = {
        theme: localStorage.getItem('theme') ?? 'auto'
    },
    action: {
        type: typeof SET_SITE_THEME;
        payload: ThemeState;
    }
): ThemeState {
    if (action.type === SET_SITE_THEME) {
        return {
            ...state,
            theme: action.payload.theme
        };
    } else {
        return state;
    }
}
