import { recursiveToCamel } from '../common';
import { ModuleExtendedPropsListType, SubmoduleDataType } from './types';

export const transformModulesData = (r: any): ModuleExtendedPropsListType => {
    const moduleVersions: { [key: number]: any } = {};
    const sourceParticipation: { [key: number]: any } = {};
    const data: ModuleExtendedPropsListType = [];

    // Module description is a part of the module version entity.
    for (const d of r['included']) {
        if (d.type === 'module-versions') {
            moduleVersions[d.id] = d;
        } else if (d.type === 'source-participation') {
            sourceParticipation[d.id] = recursiveToCamel(d);
        }
    }
    for (const d of r['data']) {
        data.push({
            module: {
                id: d.id,
                attributes: {
                    namespace: d.attributes.namespace,
                    name: d.attributes.name,
                    fullName: d.attributes['full-name'],
                    description: moduleVersions[d.relationships['latest-version'].data.id].attributes.description,
                    publishedAt: moduleVersions[d.relationships['latest-version'].data.id].attributes['published-at'],
                    downloads: d.attributes.downloads,
                    providerName: d.attributes['provider-name'],
                    isInTofuRegistry: d.attributes['is-in-tofu-registry'],
                }
            },
            sourceParticipation: !!d.relationships['source-participation'].data
                ? sourceParticipation[d.relationships['source-participation'].data.id]
                : undefined
        });
    }
    return data;
};

export const buildModuleUrl = (
    namespace: string,
    name: string,
    provider: string,
    version: string,
    submoduleId: number,
    submodules: { [key: number]: SubmoduleDataType },
    isExample: boolean,
) => {
    if (submoduleId) {
        const type = isExample ? 'examples' : 'submodules';

        return (
            `/modules/${namespace}/${name}/${provider}/${version}/${type}/${submodules[submoduleId].attributes.name}`
        );
    }

    return `/modules/${namespace}/${name}/${provider}/${version}`;
}
